import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { getId } from '../util/ID'
import AlertD from '../util/AlertD';
import { red } from '@mui/material/colors';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SearchCompany from '../game/SearchCompany';
import RuleDialog from './RuleDialog';

export default function AddAutoDialog(props) {
    const [open, setOpen] = React.useState(false);

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);

    const [oneMoney, setOneMoney] = React.useState(10);
    const [maxCount, setMaxCount] = React.useState(1);
    const [checked, setChecked] = React.useState(false);

    const [openSetting, setOpenSetting] = React.useState(false);
    const [openSetting1, setOpenSetting1] = React.useState(false);

    const [count1, setCount1] = React.useState(0);
    const [count2, setCount2] = React.useState(0);

    const [stopEarn, setStopEarn] = React.useState(20);
    const [stopLose, setStopLose] = React.useState(-20);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const join = () => {
        if (checked && localStorage.getItem('auto_search_rules1') == null) {
            setAlertMsg("至少要選擇買進策略")
            setShowAlert(true)
        } else if (localStorage.getItem("game_user_id") != null) {
            let param = {
                sn: getId(),
                gid: props.gid,
                uid: localStorage.getItem("game_user_id"),
                phone: localStorage.getItem("game_phone"),
                name: localStorage.getItem("game_name"),
                auto: {
                    status: checked ? 1 : 0,
                    one_money: oneMoney,
                    max_count: maxCount,
                    in_rules: localStorage.getItem('auto_search_rules1') != null ? JSON.parse(localStorage.getItem('auto_search_rules1')) : [],
                    out_rules: localStorage.getItem('auto_search_rules2') != null ? JSON.parse(localStorage.getItem('auto_search_rules2')) : [],
                    stop_earn : stopEarn,
                    stop_lose : stopLose
                }
            }

            if (localStorage.getItem("game_lic") != null) {
                param['lic'] = localStorage.getItem("game_lic")
            }


            fetch("/game/v1/auto/update", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result != null) {
                            if (result.status == 0) {
                                setOpen(false);
                                if (props.close != null) props.close(false)

                                if (props.refresh != null) {
                                    props.refresh()
                                }
                            } else if (result.status === -1) {
                                setAlertMsg("手機與姓名無法匹配。")
                                setShowAlert(true)
                            }
                        } else {
                            setAlertMsg("很抱歉，伺服器發生錯誤，請稍後再嘗試")
                            setShowAlert(true)
                        }
                    }
                );
        }
    }

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };


    const updateRuleCount = () => {
        if (localStorage.getItem('auto_search_rules1') != null) {
            let r1 = JSON.parse(localStorage.getItem('auto_search_rules1'))
            setCount1(r1.length)
        }

        if (localStorage.getItem('auto_search_rules2') != null) {
            let r2 = JSON.parse(localStorage.getItem('auto_search_rules2'))
            setCount2(r2.length)
        }
    }

    useEffect(() => {
        if (props.open) {
            if (props.result != null && props.result.auto != null) {
                setCount1(props.result.auto.in_rules.length)
                setCount2(props.result.auto.out_rules.length)
                localStorage.setItem("auto_search_rules1", JSON.stringify(props.result.auto.in_rules))
                localStorage.setItem("auto_search_rules2", JSON.stringify(props.result.auto.out_rules))
                setOneMoney(props.result.auto.one_money)
                setMaxCount(props.result.auto.max_count)
                setChecked(props.result.auto.status == 1)
                setStopEarn(props.result.auto.stop_earn)
                setStopLose(props.result.auto.stop_lose)
            }
            setOpen(true)
        } else setOpen(false)
    }, [props.open]);


    return (
        <div>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            {openSetting ? <RuleDialog name="auto_search_rules1" updateRuleCount={updateRuleCount} open={openSetting} close={setOpenSetting}></RuleDialog> : null}
            {openSetting1 ? <RuleDialog name="auto_search_rules2" updateRuleCount={updateRuleCount} open={openSetting1} close={setOpenSetting1}></RuleDialog> : null}


            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    {"自動交易"}
                </DialogTitle>
                <DialogContent sx={{ p: 3 }}>

                    <Stack spacing={2} direction="column" sx={{ mt: 1 }}>
                        <FormControlLabel control={<Checkbox checked={checked}
                            onChange={handleChange} />} label="啟動自動化交易。固定於10:00與13:00執行" />

                        <TextField
                            required
                            fullWidth
                            id="outlined-controlled"
                            label="初始本金百分比"
                            value={oneMoney}
                            helperText="每筆交易佔初始本金百分比"
                            onChange={(event) => {
                                setOneMoney(event.target.value);
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        />


                        <TextField
                            required
                            fullWidth
                            id="outlined-controlled"
                            label="股票支數"
                            value={maxCount}
                            helperText="每次執行最多交易幾檔股票"
                            onChange={(event) => {
                                setMaxCount(event.target.value);
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">檔</InputAdornment>,
                            }}
                        />

                        <Button onClick={e => setOpenSetting(true)} size="large" color="inherit" variant='contained' fullWidth disableElevation>買進規則設定 x {count1}</Button>
                        <Button onClick={e => setOpenSetting1(true)} size="large" color="inherit" variant='contained' fullWidth disableElevation>賣出規則設定 x {count2}</Button>

                        <TextField
                            id="stopEarn"
                            select
                            label="停利賣出"
                            value={stopEarn}
                            onChange={(event) => {
                                setStopEarn(event.target.value);
                            }}
                        >
                            {[...Array(41).keys()].map(i => {
                                return <MenuItem key={i * 0.5} value={i * 0.5}>{i * 0.5}%</MenuItem>
                            }
                            )}

                            <MenuItem value={30}>30%</MenuItem>
                            <MenuItem value={40}>40%</MenuItem>
                            <MenuItem value={50}>50%</MenuItem>
                            <MenuItem value={60}>60%</MenuItem>
                            <MenuItem value={100}>100%</MenuItem>
                            <MenuItem value={300}>300%</MenuItem>
                            <MenuItem value={500}>500%</MenuItem>
                        </TextField>
                        <TextField
                            id="stopLose"
                            select
                            label="停損賣出"
                            value={stopLose}
                            onChange={(event) => {
                                setStopLose(event.target.value);
                            }}
                        >
                            {[...Array(41).keys()].map(i => {
                                return <MenuItem key={-i * 0.5} value={-i * 0.5}>{-i * 0.5}%</MenuItem>
                            }
                            )}

                            <MenuItem value={-30}>-30%</MenuItem>
                            <MenuItem value={-40}>-40%</MenuItem>
                            <MenuItem value={-50}>-50%</MenuItem>
                            <MenuItem value={-60}>-60%</MenuItem>
                            <MenuItem value={-70}>-70%</MenuItem>
                            <MenuItem value={-100}>-100%</MenuItem>
                            <MenuItem value={-300}>-300%</MenuItem>
                            <MenuItem value={-500}>-500%</MenuItem>
                        </TextField>
                    </Stack>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>取消</Button>
                    <Button onClick={join} autoFocus>
                        確定
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
