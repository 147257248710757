import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { Add, DocumentScanner, Fingerprint, FlutterDash, Looks, PanoramaFishEye, Sailing, Watch } from '@mui/icons-material';
import { deepOrange, deepPurple, green } from '@mui/material/colors';
import AlertD from '../util/AlertD';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontSize: 16,
        whiteSpace: "nowrap"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
        whiteSpace: "nowrap"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function AutoTable(props) {
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);

    return (
        <>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}

            <TableContainer component={Paper}>
                <Table sx={{}}  size="small">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">啟動</StyledTableCell>
                            <StyledTableCell align="left">買進規則</StyledTableCell>
                            <StyledTableCell align="left">賣出規則</StyledTableCell>
                            <StyledTableCell align="left">停利</StyledTableCell>
                            <StyledTableCell align="left">停損</StyledTableCell>
                            <StyledTableCell align="center">買入上限</StyledTableCell>
                            <StyledTableCell align="center">買入資金</StyledTableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {props.result != null && props.result.auto != null && <StyledTableRow key={"1"}>
                            <StyledTableCell align="center">
                                <Button disableElevation fullWidth variant='contained' color={props.result.auto.status === 0 ? "inherit" : "error"}>{props.result.auto.status === 0 ? "未啟動" : "已啟動"}</Button>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                {props.result.auto.in_rule_desc.map((data, index) => { return (<li key={index}>{data}</li>) })}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                {props.result.auto.out_rule_desc.map((data, index) => { return (<li key={index}>{data}</li>) })}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                {props.result.auto.stop_earn}%
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                {props.result.auto.stop_lose}%
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                {props.result.auto.max_count}檔
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                {props.result.auto.one_money}%
                            </StyledTableCell>
                        </StyledTableRow>
                        }

                        {props.result == null || props.result.auto == null &&
                            <StyledTableRow key={"empty1"}>
                                <StyledTableCell align="center" colSpan={5}>{"無任何資料"}</StyledTableCell></StyledTableRow>}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
