import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TransTableAction from './TransTableAction';
import { Add, Fingerprint, Looks, Receipt, Remove, Sailing } from '@mui/icons-material';
import SellDialog from './SellDialog';
import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple, green } from '@mui/material/colors';
import AlertD from '../util/AlertD';
import { getId } from '../util/ID'
import { Chip } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PersonalDialog1 from './PersonalDialog1';
import WatchAction from './WatchAction';
// import { Adsense } from '@ctrl/react-adsense';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontSize: 16,
        whiteSpace: "nowrap"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
        whiteSpace: "nowrap"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function WatchTable(props) {
    const [openSetting, setOpenSetting] = React.useState(false);
    const [row, setRow] = React.useState(null);
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);
    const [openSetting1, setOpenSetting1] = React.useState(false);


    const getStatus = (status) => {
        if (status === 0) return "隱藏中"
        else if (status === 1) return "尚未開始"
        else if (status === 2) return "活動中"
        else if (status === 3) return "已結束"
        else if (status === 4) return "已刪除"
        else return ""
    }

    const open = (value) => {
        if (value.game.in_grace || value.game.status === 3) {
            setRow(value)
            setOpenSetting1(true)
        } else {
            setAlertMsg("此刻已無法查看交易紀錄。")
            setShowAlert(true)
        }

        // if (value.game.point > props.point && !value.game.in_grace) {
        //     setAlertMsg("點數不足，無法查看交易紀錄。")
        //     setShowAlert(true)
        // } else {
        //     setRow(value)
        //     setOpenSetting1(true)
        // }
    }

    return (
        <Box sx={{ p: 1 }}>
            {openSetting ? <SellDialog row={row} refresh={props.refresh} gid={props.gid} open={openSetting} close={setOpenSetting}></SellDialog> : null}
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            {openSetting1 ? <PersonalDialog1 endYMD={row.end_ymd}  gid={row.gid} wid={row.wid} open={openSetting1} close={setOpenSetting1}></PersonalDialog1> : null}

            <Box sx={{ mb: 1 }}>
                <Typography sx={{ width: "100%",  fontSize: 24, fontWeight: "bold" }}>關注清單</Typography>
                {/* <Typography sx={{ width: "100%", textAlign: "center", fontSize: 18, }}>您目前的點數為{props.point}點</Typography> */}
            </Box>

            {/* <Adsense
                client="ca-pub-8613274553211553"
                slot="1654340287"
                style={{ display: 'block' }}
                layout="in-article"
                format="fluid"
            /> */}
            
            <TableContainer component={Paper}>
                <Table sx={{}} size='small' >
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">名次</StyledTableCell>
                            <StyledTableCell align="center">暱稱</StyledTableCell>

                            <StyledTableCell align="center">名稱</StyledTableCell>
                            <StyledTableCell align="center">狀態</StyledTableCell>
                            
                            <StyledTableCell align="center">報酬率</StyledTableCell>
                            <StyledTableCell align="center">勝率</StyledTableCell>
                            <StyledTableCell align="center">日誌</StyledTableCell>
                            <StyledTableCell align="center">交易</StyledTableCell>
                            {/* <StyledTableCell align="center">關注人數</StyledTableCell> */}
                            <StyledTableCell align="center">最後更新</StyledTableCell>
                            <StyledTableCell align="center">庫存</StyledTableCell>
                            <StyledTableCell align="center">買&賣次數</StyledTableCell>
                            
                            <StyledTableCell align="center">動作</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.result != null && props.result.watch != null && props.result.watch.map((row) => (
                            <StyledTableRow key={row.gid + row.wid}>
                                {/* <StyledTableCell align="left">
                                    <Avatar sx={{ bgcolor: "#344157" }} variant="square">
                                        {row.leader.score}
                                    </Avatar>
                                </StyledTableCell> */}
                                <StyledTableCell align="center">
                                    <Typography fontSize={18} fontWeight={"bold"}>{row.leader.score}</Typography>
                                </StyledTableCell>
                                <StyledTableCell align="center">{row.leader.name}</StyledTableCell>
                                <StyledTableCell align="center">
                                    {/* <Chip size='small' label={getStatus(row.game.status)}></Chip> */}
                                    {row.game.name}，第{row.game.num}場
                                </StyledTableCell>
                                <StyledTableCell align="center"><Button color="inherit"  disabled={row.game.status != 2} variant="text">{getStatus(row.game.status)}</Button></StyledTableCell>
                                
                                <StyledTableCell align="center">
                                    <Button size="small" fullWidth disableElevation variant='contained' color={row.leader.roi >= 0 ? "error" : "success"}>{row.leader.roi}%</Button>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <Button size="small" color={row.leader.win_rate > 60 ? "error" : "inherit"} fullWidth disableElevation variant="contained">
                                        {row.leader.win_rate}%</Button></StyledTableCell>

                                <StyledTableCell align="center">
                                    <Button size="small" onClick={e => open(row)} color="inherit" startIcon={<Receipt />} sx={{}} variant='contained' disableElevation fullWidth>操盤紀錄</Button>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {row.leader.auto === 0 ? "手動" : "自動化交易"}
                                </StyledTableCell>
                                {/* <StyledTableCell align="center">
                                    {row.leader.watched}人
                                </StyledTableCell> */}
                                <StyledTableCell align="center">
                                    {row.leader.last_update}小時前
                                </StyledTableCell>
                                <StyledTableCell align="center">{row.leader.storage_size}檔</StyledTableCell>
                                <StyledTableCell align="center">{row.leader.buy_count}次 / {row.leader.sell_count}次</StyledTableCell>


                                <StyledTableCell align="center">
                                    {<WatchAction row={row} refresh={props.refresh} />}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}

                        {(props.result == null || (props.result != null && props.result.watch != null
                            && props.result.watch.length === 0)) &&
                            <StyledTableRow key={"empty1"}>
                                <StyledTableCell align="center" colSpan={12}>{"無任何資料"}</StyledTableCell></StyledTableRow>}


                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
