import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AlertD from '../util/AlertD';
import ActionRule from './ActionRule';
import ActionRule1 from './ActionRule1';

export default function ActionRuleDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };

    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);


    return (
        <div>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}

            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    {props.game.name}
                </DialogTitle>
                <DialogContent sx={{ p: 3 }}>
                    {(props.game.id === "1" || props.game.id === "2" || props.game.id === "3") && <ActionRule game={props.game} />}
                    {!(props.game.id === "1" || props.game.id === "2" || props.game.id === "3") && <ActionRule1 game={props.game} />}
                </DialogContent>
                <DialogActions>
                    <Button fullWidth onClick={handleClose}>關閉</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
