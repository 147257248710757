import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Stack, Typography } from '@mui/material';
import ReportStep from './ReportStep';
import { Height } from '../util/ScreenHeight'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function ExplainPage(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{ width: '100%', position: 'absolute', top: Height.header, bottom: props.ismobile || fullScreen ? 55 : 0, overflowY: 'scroll' }}>
      <Container maxWidth="sm">
        <Box>
          <Typography sx={{mt:2}} fontWeight={"bold"} variant='h5'>股市遊樂園能有什麼幫助？</Typography>
          <Typography variant='body1'>1. 觀摩學習：透過模擬投資，觀察專家的操作策略，從中學習或跟隨投入股市。</Typography>
          <Typography variant='body1'>2. 賺取獎金：在模擬投資中獲得的累積收益可以按照特定匯率轉換成現金。</Typography>

          <Typography sx={{mt:6}} fontWeight={"bold"} variant='h5'>是否收取報名費？</Typography>
          <Typography variant='body1'>目前都是免費參加的。</Typography>

          <Typography sx={{mt:6}} fontWeight={"bold"} variant='h5'>參賽資格有哪些要求？</Typography>
          <Typography variant='body1'>參加者需年滿十八歲並提供有效手機號碼，兌換現金需要有效能驗證的手機號碼。</Typography>


          <Typography sx={{mt:6}} fontWeight={"bold"} variant='h5'>如何得到獎金</Typography>
          <Typography variant='body1'>到獎金/帳號的頁面，可以看到個人的累積收益，達到一定金額可以點選兌換按鈕，輸入個人銀行帳號，獎金在10個工作日就會匯入。</Typography>

          <Typography sx={{mt:6}} fontWeight={"bold"} variant='h5'>兌換獎金的匯率是固定的嗎?</Typography>
          <Typography variant='body1'>不是固定的。兌換的匯率每場比賽結束後可能會有異動。</Typography>


          <Typography sx={{mt:6}} fontWeight={"bold"} variant='h5'>模擬投資的場次很多嗎?</Typography>
          <Typography variant='body1'>每個月至少有1-3場以上。</Typography>

          <Typography sx={{mt:6}} fontWeight={"bold"} variant='h5'>兌換獎金有什麼要注意的嗎?</Typography>
          <Typography variant='body1'>手機號碼要先驗證成功，填寫正確的銀行帳戶，等待10個工作日，獎金就會匯入。</Typography>

          {/* <Typography variant='body1'>這是一個經常舉辦各種股市投資模擬比賽的軟體。</Typography> */}


          {/* <Typography sx={{mt:6}} fontWeight={"bold"} variant='h5'>是否收取報名費？參賽資格有哪些要求？</Typography>
          <Typography variant='body1'>每場比賽資訊右上角會標示是否需要報名費，目前大多數比賽都是免費參加的。參加者需年滿十八歲並提供有效手機號碼。獲勝者將透過簡訊通知，同時需要提交身分證影本以供申報之用。</Typography>

          <Typography sx={{mt:6}} fontWeight={"bold"} variant='h5'>如何得到獎金</Typography>
          <Typography variant='body1'>每場比賽的盈虧可以累積達到一定金額就可以兌換現金。兌換的匯率可能會常常變動。</Typography>

          <Typography sx={{ mt: 6 }} fontWeight={"bold"} variant='h5'>如何開始比賽</Typography>
          <Typography variant='body1'>下面是進入股市遊樂場的重要步驟。</Typography>

          <ReportStep />

          <Typography fontWeight={"bold"} variant='h5'>填寫基本資料</Typography>
          <Typography variant='body1'>到個人基本資料的頁面，確實填寫手機號碼與姓名的基本資訊，填寫錯誤將無法收到簡訊通知或證明本人領獎。</Typography>


          <Typography sx={{ mt: 6 }} fontWeight={"bold"} variant='h5'>比賽種類</Typography>
          <Typography variant='body1'>目前只有一款與個股買賣相關的遊戲：「買賣神之手」。遊戲透過最基本的買進與賣出個股操作來實現報酬率，最後根據報酬率最高的參賽者進行結算，獲得最終勝利。</Typography> */}

          {/* <Typography sx={{ mt: 6 }} fontWeight={"bold"} variant='h5'>是否可以查看其他人的交易記錄？</Typography>
          <Typography variant='body1'>在比賽剛開始的幾天和比賽結束後，都會免費開放觀看其他參賽者的交易記錄。但在比賽進行期間，若要查看其他人的交易紀錄，您需要擁有足夠的點數。</Typography> */}


          {/* <Typography sx={{ mt: 6 }} fontWeight={"bold"} variant='h5'>如何買進股票？</Typography>
          <Typography variant='body1'>報名完成後，進入我的操盤→點選買進的按鈕。</Typography>
          <img src="e2.png" />

          <Typography sx={{ mt: 2 }} variant='body1'>輸入股票代號，價格，股數。只有台股開盤時才有機會完成買入，其餘時段都是預約單。個股漲停時，無法買入，跌停時無法賣出。</Typography>
          <img src="e3.png" />

          <Typography sx={{ mt: 6 }} fontWeight={"bold"} variant='h5'>如何看自己的績效與排名？</Typography>
          <Typography variant='body1'>進入我的操盤，會顯示下面的資訊。</Typography>
          <Stack spacing={1} sx={{ mt: 0.2 }}>
            <Typography variant='body1'>帳戶餘額：目前帳戶有多少錢可以用來買入股票。</Typography>
            <Typography variant='body1'>總報酬率：用已賣出的交易與現有的庫存計算最新的報酬率為多少。</Typography>
            <Typography variant='body1'>排名：在這場比賽中目前的名次是第幾名。每天15:00更新一次最新排名。</Typography>
          </Stack>
          <img src="e1.png" />

          <Typography sx={{ mt: 6 }} fontWeight={"bold"} variant='h5'>如何賣出股票？</Typography>
          <Typography variant='body1'>報名完成後，進入我的操盤→庫存→點選要賣的股票後方的賣出按鈕。</Typography>
          <img src="e4.png" />

          <Typography sx={{ mt: 2 }} variant='body1'>輸入價格，股數。只有台股開盤時才有機會成功賣出，其餘時段都是預約單。個股漲停時，無法買入，跌停時無法賣出。</Typography>
          <img src="e5.png" />


          <Typography sx={{ mt: 6 }} fontWeight={"bold"} variant='h5'>如何看別人的績效？</Typography>
          <Typography variant='body1'>點擊「績效榜」，即可查看目前排名前100位的參賽者。該列表包括名次、使用者暱稱、報酬率，以及以下相關資訊：</Typography>
          <Stack spacing={0.2} sx={{ mt: 1 }}>
            <Typography variant='body1'>操盤日誌：對手的所有買賣紀錄。</Typography>
            <Typography variant='body1'>關注人數：有多少人關注。</Typography>
            <Typography variant='body1'>最後更新：最後一次交易是多久以前的事情。</Typography>
            <Typography variant='body1'>庫存：庫存中還有幾檔股票。</Typography>
            <Typography variant='body1'>買&賣次數：此比賽進行了多少次的買進交易與賣出交易。</Typography>
            <Typography variant='body1'>勝率：賣出股票時賺錢的機率多大。</Typography>
            <Typography variant='body1'>加入觀察：類似我的最愛，可以在關注清單上，隨時可以知道此對手目前的資訊。</Typography>


          </Stack>

          <img src="e6.png" /> */}


        </Box>
      </Container>
    </Box>
  );
}
