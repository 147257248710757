import { green, grey, red } from "@mui/material/colors"

export const priceWord = (num) => {
    if (num >= 100000000 || num <= -100000000) {
        let k = num / 100000000
        if (k % 1 === 0) {
            return k.toFixed(0) + "億元"
        } else return k.toFixed(0) + "億元"
    } else if (num >= 10000000 || num <= -10000000) {
        let k = num / 10000000
        if (k % 1 === 0) {
            return k.toFixed(0) + "千萬元"
        } else return k.toFixed(0) + "千萬元"
    } else if (num >= 10000 || num <= -10000) {
        let k = num / 10000
        if (k % 1 === 0) {
            return k.toFixed(0) + "萬元"
        } else return k.toFixed(0) + "萬元"
    } else return num + "元"
}


export const addIncIcon = (num) => {
    if (num > 0) {
        return "▲" + num + "%"
    } else if (num < 0) {
        return "▼" + num + "%"
    } else return num + "%"
}


export const addIncColor = (num) => {
    if (num > 0) {
        return red[500]
    } else if (num < 0) {
        return green[500]
    } else return null//grey[500]
}


export const daysWord = (num) => {
    if (num > 365) {
        return (num / 365.0).toFixed(0) + "年"
    } else if (num > 100) {
        return (num / 30.0).toFixed(0) + "月"
    } else return num + "天"
}

{/* <MenuItem value={1}>每個月</MenuItem>
<MenuItem value={3}>每1季</MenuItem>
<MenuItem value={6}>每半年</MenuItem>
<MenuItem value={12}>每1年</MenuItem> */}

export const growthCycleData = () => {
    return [{
        n: "每個月",
        v: 1
    }, {
        n: "每1季",
        v: 3
    }, {
        n: "每半年",
        v: 6
    }, {
        n: "每1年",
        v: 12
    }]
}

export const getCycleWord = (v) => {
    if(v === 1) return "每個月"
    else if(v === 3) return "每1季"
    else if(v === 6) return "每半年"
    else if(v === 12) return "每一年"
    else return ""
}

export const getGrowthTpWord = (v) => {
    if(v === 1) return "增加"
    else if(v === 2) return "減少"
    else return ""
}


export const getIntRateWord = (v) => {
    if(v === 1) return "本息均攤"
    else if(v === 2) return "本金均攤"
    else if(v === 3) return "固定利息"
    else if(v === 4) return "無利息"
    else return ""
}