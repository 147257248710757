import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { getId } from '../util/ID'
import AlertD from '../util/AlertD';
import { red } from '@mui/material/colors';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TransTables from './TransTable';
import { Fingerprint, Refresh } from '@mui/icons-material';
import PersonalCard1 from './PersonalCard1';
import BuySellDialog from './BuySellDialog';
import StorageTable from './StorageTable';
import SellDialog from './SellDialog';
import AutoTable from '../auto/AutoTable';
// import { Adsense } from '@ctrl/react-adsense';
import StatisticsShortTable from './StatisticsShortTable';

export default function StatisticsDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);

    const [result, setResult] = React.useState(null)

    const get = () => {
        let param = {
            sn: getId(),
            gid: props.gid,
            uid: localStorage.getItem("game_user_id"),
            phone: localStorage.getItem("game_phone"),
            name: localStorage.getItem("game_name"),
        }

        if (localStorage.getItem("game_lic") != null) {
            param['lic'] = localStorage.getItem("game_lic")
        }


        fetch("/game/v1/statistics/get", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        if (result.status == 0) {
                            setResult(result.statics.statistic)
                            if (props.refreshPoint != null) props.refreshPoint()
                        } else {
                            setAlertMsg("此刻已無法查看交易紀錄。")
                            setShowAlert(true)
                        }

                        // else if (result.status === -1) {
                        //     setAlertMsg("個人基本資料有問題。")
                        //     setShowAlert(true)
                        // }else if (result.status === -2) {
                        //     setAlertMsg("點數不足，無法查看交易紀錄。")
                        //     setShowAlert(true)
                        // }
                    } else {
                        setAlertMsg("很抱歉，伺服器發生錯誤，請稍後再嘗試")
                        setShowAlert(true)
                    }
                }
            );
    }



    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };

    useEffect(() => {
        if (props.open) {
            get()
            setOpen(true)
        } else setOpen(false)
    }, [props.open]);


    return (
        <div>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}

            <Dialog
                fullScreen
                fullWidth={true}
                // maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >

                <DialogContent sx={{ p: 2 }}>
                    {/* <Adsense
                        client="ca-pub-8613274553211553"
                        slot="1654340287"
                        style={{ display: 'block' }}
                        layout="in-article"
                        format="fluid"
                    /> */}

                    <Box display={"flex"} sx={{ mt: 4, mb: 1 }}>
                        <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>庫存統計表</Typography>
                    </Box>
                    {result != null && <StatisticsShortTable label="庫存" inGrace={props.inGrace} refreshPoint={props.refreshPoint} refresh={get} count={result.r1.length} result={result.r1} gid={props.gid} dPoint={props.dPoint} point={result.point} />}


                    <Divider sx={{ mt: 4, mb: 2 }}></Divider>

                    <Box display={"flex"} sx={{ mb: 1 }}>
                        <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>預約單統計表</Typography>
                    </Box>
                    {result != null && <StatisticsShortTable label="預約單" inGrace={props.inGrace} refreshPoint={props.refreshPoint} refresh={get} count={result.r2.length} result={result.r2} gid={props.gid} dPoint={props.dPoint} point={result.point} />}

                </DialogContent>
                <DialogActions>
                    <Button disableElevation variant='contained' color="inherit" fullWidth onClick={handleClose}>關閉</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
