import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import { getId } from '../util/ID'
import AlertD from '../util/AlertD';
import { red } from '@mui/material/colors';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ActionRule from './ActionRule';


export default function SignUp1(props) {
    const [open, setOpen] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);
    const [checked, setChecked] = React.useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

   
    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };

    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);


    const fectchAPIK = () => {
        if (!checked) {
            setAlertMsg("閱讀完規則，記得選取同意")
            setShowAlert(true)            
        } else if(localStorage.getItem("game_user_id") == null) {
            setAlertMsg("先填寫基本資料")
            setShowAlert(true)            
        } else {
            let url = "/game/v1/payment/donate?sn=" + getId() + "&tp=69&lic=" + props.gid + "_" + localStorage.getItem("game_user_id")  + "#open"
            window.open(url, "_blank", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=1024,height=800");
            handleClose()
        }
    }

    return (
        <div>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}

            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    {"報名" + props.game.name}
                </DialogTitle>
                <DialogContent sx={{ p: 3 }}>

                    <ActionRule game={props.game} />

                    <FormGroup>
                        <FormControlLabel required control={<Checkbox
                            checked={checked}
                            onChange={handleChange}
                        />
                        } label="同意並會遵守遊戲規則" />
                    </FormGroup>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>取消</Button>
                    <Button onClick={fectchAPIK} autoFocus>
                        信用卡支付報名費{props.game.pay}元
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
