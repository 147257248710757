import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { Add, DocumentScanner, Fingerprint, FlutterDash, Looks, NightShelter, PanoramaFishEye, Sailing, Watch } from '@mui/icons-material';
import SellDialog from './SellDialog';
import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple, green, orange, purple, red, blue, grey } from '@mui/material/colors';
import AlertD from '../util/AlertD';
import { getId } from '../util/ID'
import PersonalDialog1 from './PersonalDialog1';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import WatchDialog from './WatchDialog';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontSize: 16,
        whiteSpace: "nowrap"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
        whiteSpace: "nowrap"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // '&:nth-of-type(odd)': {
    //     backgroundColor: theme.palette.action.hover,
    // },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function LeaderBoardTable(props) {
    const theme = useTheme();

    const [row, setRow] = React.useState(null);
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);
    // const [openSetting, setOpenSetting] = React.useState(false);
    const [openSetting1, setOpenSetting1] = React.useState(false);
    const [wid, setWid] = React.useState(null);
    const [name, setName] = React.useState(null);
    const [point, setPoint] = React.useState(0)
    const [openSetting2, setOpenSetting2] = React.useState(false);

    const addWatch = (event, wid) => {
        if (event.stopPropagation) {
            event.stopPropagation();
        }

        if (localStorage.getItem("game_user_id") != null) {
            let param = {
                sn: getId(),
                gid: props.gid,
                uid: localStorage.getItem("game_user_id"),
                phone: localStorage.getItem("game_phone"),
                name: localStorage.getItem("game_name"),
                wid: wid
            }

            if (localStorage.getItem("game_lic") != null) {
                param['lic'] = localStorage.getItem("game_lic")
            }


            fetch("/game/v1/watch/add", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result != null && result.status === 0) {
                            setAlertMsg("關注成功")
                            setShowAlert(true)
                            if (props.refresh != null) props.refresh()
                        }
                    }
                );
        }
    }

    const seeReport = (event, uid) => {
        if (event.stopPropagation) {
            event.stopPropagation();
        }

        if (props.gameStatus1 === 3 || props.inGrace) {
            setWid(uid)
            setOpenSetting1(true)
        } else {
            setAlertMsg("此刻已無法查看交易紀錄。")
            setShowAlert(true)
        }
        // } else if (props.dPoint > props.point) {
        //     setAlertMsg("點數不足，無法查看交易紀錄。")
        //     setShowAlert(true)
        // } else {
        //     setWid(uid)
        //     setOpenSetting1(true)
        // }
    }

    const getBgColor = (value) => {
        if (localStorage.getItem("game_user_id") != null && localStorage.getItem("game_user_id") === value) {
            return theme.palette.mode === 'dark' ? blue[900] : blue[100]
        } else return null
    }

    const openWatch = (event, wid, name) => {
        if (event.stopPropagation) {
            event.stopPropagation();
        }

        setName(name)
        setWid(wid)
        setOpenSetting2(true)
    }

    return (
        <>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            {/* {openSetting ? <PointAlertDialog open={openSetting} close={setOpenSetting}></PointAlertDialog> : null} */}
            {openSetting1 && <PersonalDialog1 endYMD={props.endYMD} refreshPoint={props.refreshPoint} gid={props.gid} wid={wid} open={openSetting1} close={setOpenSetting1}></PersonalDialog1>}
            {openSetting2 ? <WatchDialog name={name} wid={wid} open={openSetting2} close={setOpenSetting2}></WatchDialog> : null}


            <TableContainer component={Paper}>
                <Table sx={{}} size='small' >
                    <TableHead>
                        <TableRow>
                            <StyledTableCell  align="center">名次</StyledTableCell>
                            <StyledTableCell sx={{ zIndex: 999, position: "sticky", left: 0 }} align="center">暱稱</StyledTableCell>
                            <StyledTableCell align="center">報酬率</StyledTableCell>
                            <StyledTableCell align="center">勝率</StyledTableCell>
                            <StyledTableCell align="center">日誌</StyledTableCell>
                            <StyledTableCell align="center">戰績</StyledTableCell>
                            <StyledTableCell align="center">交易</StyledTableCell>
                            <StyledTableCell align="center">關注人數</StyledTableCell>
                            <StyledTableCell align="center">最後更新</StyledTableCell>
                            <StyledTableCell align="center">庫存</StyledTableCell>
                            <StyledTableCell align="center">買&賣次數</StyledTableCell>
                            
                            <StyledTableCell align="center">動作</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.result != null && props.result.map((row) => (
                            <StyledTableRow key={row.score}  onClick={e => seeReport(e, row.uid)}>
                                <StyledTableCell align="center" style={{ backgroundColor: getBgColor(row.uid) }}>
                                    <Typography fontSize={18} fontWeight={"bold"}>{row.score}</Typography>
                                </StyledTableCell>
                                <StyledTableCell align="center" 
                                sx={{zIndex: 998, position: "sticky", left: 0, bgcolor: theme.palette.mode === "light" ? grey[50] : grey[900], color: theme.palette.mode === "light" ? "#000000" : "#FFFFFF" }}
                                >{row.name.length > 10 ? row.name.substr(0, 10) + "..." : row.name}</StyledTableCell>
                                <StyledTableCell align="center">
                                    <Button fullWidth disableElevation variant='contained' color={row.roi >= 0 ? "error" : "success"} size='small'>{row.roi}%</Button>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <Button color={row.win_rate > 60 ? "error" : "inherit"} fullWidth disableElevation variant="contained" size='small'>
                                        {row.win_rate}%</Button></StyledTableCell>
                                <StyledTableCell align="center">
                                    <Button disabled={!(props.gameStatus1 === 3 || props.inGrace)} onClick={e => seeReport(e, row.uid)} color="inherit" size='small' sx={{}} variant='contained' disableElevation fullWidth>操盤紀錄</Button>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <Button onClick={e => openWatch(e, row.uid, row.name)} color="inherit" sx={{}} variant='contained' disableElevation fullWidth size='small' >歷史戰績</Button>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {row.auto === 0 ? "手動" : "自動化交易"}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {row.watched}人
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {row.last_update}小時前
                                </StyledTableCell>
                                <StyledTableCell align="center">{row.storage_size}檔</StyledTableCell>
                                <StyledTableCell align="center">{row.buy_count}次 / {row.sell_count}次</StyledTableCell>


                                <StyledTableCell align="center">
                                    <Button disabled={localStorage.getItem("game_user_id") == null} onClick={e => addWatch(e, row.uid)} color="inherit" size='small' startIcon={<Add />} sx={{}} variant='contained' disableElevation fullWidth>觀察</Button>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}

                        {props.result != null && props.result.length === 0 &&
                            <StyledTableRow key={"empty1"}>
                                <StyledTableCell align="center" colSpan={11}>{"無任何資料"}</StyledTableCell></StyledTableRow>}


                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
