import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';
import { priceWord } from '../util/Money'
import { green, red } from '@mui/material/colors';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function HeroCard(props) {


    return (
        <>
            {props.result != null && <Box sx={{ flexGrow: 1, mb: 4, mt: 1, p: 1 }}>
                <Grid container spacing={2} sx={{
                    '--Grid-borderWidth': '1px',
                    borderTop: 'var(--Grid-borderWidth) solid',
                    borderLeft: 'var(--Grid-borderWidth) solid',
                    borderColor: 'divider',
                    '& > div': {
                        borderRight: 'var(--Grid-borderWidth) solid',
                        borderBottom: 'var(--Grid-borderWidth) solid',
                        borderColor: 'divider',
                    },
                }}>
                    <Grid xs={12} md={4}>
                        <Box sx={{ textAlign: "center" }}>
                            <Typography fontSize={12}>級數</Typography>
                            <Typography fontWeight={"bold"} fontSize={52}>{props.result.rate}級</Typography>
                        </Box>
                    </Grid>
                    <Grid xs={12} md={4}>
                        <Box sx={{ textAlign: "center" }}>
                            <Typography fontSize={12}>累積報酬率</Typography>
                            <Typography fontWeight={"bold"} fontSize={52} sx={{ color: props.result != null && props.result.roi >= 0 ? red[600] : green[600] }}>{props.result.roi}%</Typography>
                        </Box>
                    </Grid>
                    <Grid xs={12} md={4}>
                        <Box sx={{ textAlign: "center" }}>
                            <Typography fontSize={12}>累積收益</Typography>
                            <Typography fontWeight={"bold"} fontSize={52} sx={{ color: props.result != null && props.result.roi >= 0 ? red[600] : green[600] }} >{priceWord(props.result.earn)}</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>}
        </>
    );
}
