import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AlertD from '../util/AlertD';
import ActionRule from './ActionRule';
import { Stack, TextField } from '@mui/material';

export default function PhoneVerify(props) {
    const [open, setOpen] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);
    const [disableSend, setDisableSend] = React.useState(false);
    const [pin, setPin] = React.useState("");

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };

    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);

    const getPin = () => {
        let param = {
            uid: localStorage.getItem("game_user_id"),
            phone: localStorage.getItem("game_phone"),
            name: localStorage.getItem("game_name"),
        }

        if (localStorage.getItem("game_lic") != null) {
            param['lic'] = localStorage.getItem("game_lic")
        }


        fetch("/game/v1/phone/get_pin", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        if (result.status == 0) {
                            setDisableSend(true)
                        }
                    }
                }
            );
    }


    const verifyPin = () => {
        if (pin != null && pin != "") {
            let param = {
                uid: localStorage.getItem("game_user_id"),
                phone: localStorage.getItem("game_phone"),
                name: localStorage.getItem("game_name"),
                pin: pin
            }

            if (localStorage.getItem("game_lic") != null) {
                param['lic'] = localStorage.getItem("game_lic")
            }


            fetch("/game/v1/phone/verify_pin", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result != null) {
                            if (result.status == -1) {
                                localStorage.removeItem("phone_verify")
                                setAlertMsg("帳號驗證錯誤")
                                setShowAlert(true)
                            } else if (result.status == -2 || result.status == -4) {
                                localStorage.removeItem("phone_verify")
                                setAlertMsg("驗證碼不正確")
                                setShowAlert(true)
                            } else if (result.status == -3) {
                                localStorage.removeItem("phone_verify")
                                setAlertMsg("驗證碼超過期限")
                                setShowAlert(true)
                            } else if (result.status == 0) {
                                localStorage.setItem("phone_verify", "1")
                                if (props.refresh != null) props.refresh()
                                handleClose()
                            }
                        }
                    }
                );
        } else {
            setAlertMsg("請輸入驗證碼")
            setShowAlert(true)
        }
    }
    return (
        <div>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}

            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    驗證
                </DialogTitle>
                <DialogContent sx={{ p: 3 }}>
                    <Stack spacing={1.5}>
                        <TextField
                            label="手機號碼"
                            id="filled-size-small"
                            defaultValue={props.phone}
                            variant="standard"
                            fullWidth
                            disabled
                            size="small"
                        />
                        <Stack direction={"row"}>
                            <TextField
                                label="驗證碼"
                                id="pin"
                                variant="standard"
                                fullWidth
                                size="small"
                                value={pin}
                                onChange={(event) => {
                                    setPin(event.target.value);
                                }}
                            />
                            <Button onClick={e => getPin()} disabled={disableSend} size='small' disableElevation variant='contained' sx={{ whiteSpace: "nowrap", borderRadius: 0 }}>取得驗證碼</Button>
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button fullWidth onClick={handleClose}>關閉</Button>
                    <Button fullWidth onClick={verifyPin}>驗證</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
