import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { Height } from '../util/ScreenHeight'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Chip, Divider, ListItemButton, Paper } from '@mui/material';
import { blue, deepOrange, green, grey, purple, red } from '@mui/material/colors';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import { Assessment, Bolt, ContactMail, Payment, RuleFolder } from '@mui/icons-material';

import SignUp from './SignUp';
import { getId } from '../util/ID'
import PersonalDialog from './PersonalDialog';
import LeaderBoard from './LeaderBoard';
import AlertD from '../util/AlertD';
import ActionRuleDialog from './ActionRuleDialog';
// import { Adsense } from '@ctrl/react-adsense';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SignUp1 from './SignUp1';
import BluetoothAudioIcon from '@mui/icons-material/BluetoothAudio';
import SvgIcon from '@mui/material/SvgIcon';
// import { Adsense } from '@ctrl/react-adsense';

export default function Game(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [openSetting, setOpenSetting] = React.useState(false);
    const [openSetting1, setOpenSetting1] = React.useState(false);
    const [openSetting2, setOpenSetting2] = React.useState(false);
    const [openSetting3, setOpenSetting3] = React.useState(false);
    const [openSetting4, setOpenSetting4] = React.useState(false);

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);

    const [games, setGames] = React.useState([]);
    const [payGames, setPayGames] = React.useState([]);

    const [gid, setGid] = React.useState(null);
    const [row, setRow] = React.useState(null);
    const [dPoint, setDPoint] = React.useState(null)
    const [inGrace, setInGrace] = React.useState(false)
    const [gameStatus, setGameStatus] = React.useState(localStorage.getItem("game_selector") != null ? parseInt(localStorage.getItem("game_selector")) : 4);
    const [gameStatus1, setGameStatus1] = React.useState(0);
    const [endYMD, setEndYMD] = React.useState(null);

    // 1:尚未開始, 2:開始, 3:結束,
    const handleGameStatusChange = (event) => {
        setGameStatus(event.target.value);
        localStorage.setItem("game_selector", event.target.value)
    };

    const join = (value) => {
        if (localStorage.getItem("game_user_id") != null) {
            setGid(value.id)
            setRow(value)
            setOpenSetting(true)
        } else {
            setAlertMsg("請先填寫個人基本資料")
            setShowAlert(true)
        }
    }

    const join1 = (value) => {
        if (localStorage.getItem("game_user_id") != null) {
            setGid(value.id)
            setRow(value)
            setOpenSetting4(true)
        } else {
            setAlertMsg("請先填寫個人基本資料")
            setShowAlert(true)
        }
    }

    const play = (value, end) => {
        setGid(value)
        setEndYMD(end)
        setOpenSetting1(true)
    }

    const showDashboard = (value, value1, value2, value3, end) => {
        setGid(value)
        setDPoint(value1)
        setInGrace(value2)
        setGameStatus1(value3)
        setEndYMD(end)
        setOpenSetting2(true)
    }

    const showRule = (value) => {
        setRow(value)
        if (value.tp === 0 || value.tp === 1)
            setOpenSetting3(true)
    }


    useEffect(() => {
        getGames()
    }, [gameStatus]);

    const getGames = () => {
        let param = {
            sn: getId(),
            game_status: gameStatus
        }

        if (localStorage.getItem("game_lic") != null) {
            param['lic'] = localStorage.getItem("game_lic")
        }


        if (localStorage.getItem("game_user_id") != null) {
            param['uid'] = localStorage.getItem("game_user_id")
        }


        fetch("/game/v1/app/get1", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null && result.status === 0) {
                        setGames(result.free)
                        setPayGames(result.pay)
                    }
                }
            );
    }

    const getTitle = (row) => {
        if (row.is_join) {
            return "我的名次"
        } else return "最高報酬率(%)"
    }

    const getValue = (row) => {
        if (row.is_join && row.left != null && row.left.index != null) {
            return row.left.index
        } else if (!row.is_join && row.left != null && row.left.roi != null) {
            return row.left.roi.toFixed(1)
        } else return "-"
    }

    const getStatus = (status) => {
        if (status === 0) return "隱藏中"
        else if (status === 1) return "尚未開始"
        else if (status === 2) return "活動中"
        else if (status === 3) return "已結束"
        else if (status === 4) return "已刪除"
        else return ""
    }

    const getStatusColor = (status) => {
        if (status === 0) return "default"
        else if (status === 1) return "default"
        else if (status === 2) return "error"
        else if (status === 3) return "success"
        else if (status === 4) return "default"
        else return "default"
    }

    function dateDiffInDays(a, b) {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        // Discard the time and time-zone information.
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    }

    function toDate(dateString) {
        var year = dateString.substring(0, 4);
        var month = dateString.substring(4, 6);
        var day = dateString.substring(6, 8);

        return new Date(year, month - 1, day);
    }

    // function getRandomInt(max) {
    //     return Math.floor(Math.random() * max);
    // }

    // const getNum = () => {
    //     let k = getRandomInt(11) + 1
    //     if (k < 10) return "0" + k
    //     else return k + ""
    // }

    return (
        <>
            {openSetting ? <SignUp game={row} refresh={getGames} gid={gid} open={openSetting} close={setOpenSetting}></SignUp> : null}
            {openSetting1 ? <PersonalDialog endYMD={endYMD} gid={gid} open={openSetting1} close={setOpenSetting1}></PersonalDialog> : null}
            {openSetting2 && <LeaderBoard endYMD={endYMD}  gameStatus1={gameStatus1} inGrace={inGrace} dPoint={dPoint} gid={gid} open={openSetting2} close={setOpenSetting2}></LeaderBoard>}
            {openSetting3 ? <ActionRuleDialog game={row} open={openSetting3} close={setOpenSetting3}></ActionRuleDialog> : null}
            {openSetting4 ? <SignUp1 game={row} refresh={getGames} gid={gid} open={openSetting4} close={setOpenSetting4}></SignUp1> : null}

            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}

            <Box sx={{ width: '100%', position: 'absolute', top: Height.header, bottom: props.ismobile || fullScreen ? 55 : 0, overflowY: 'scroll' }}>

                <Container sx={{ p: 2 }} maxWidth="md">
                    <Grid container spacing={2.5}>
                        {/* <Grid item key={"ad"} xs={12} sm={12} md={12}>
                            <Adsense
                                client="ca-pub-8613274553211553"
                                slot="6111625899"
                                style={{ display: 'block' }}
                                layout="in-article"
                                format="fluid"
                            />
                        </Grid> */}

                        <Grid item key={"select"} xs={12} sm={12} md={12}>
                            <Stack direction={"row"} spacing={2}>
                                {/* <Box sx={{ alignSelf: "center" }}>
                                    <Typography fontSize={12} sx={{ whiteSpace: "nowrap" }}>累積發送獎金</Typography>
                                    <Typography fontSize={24} sx={{ whiteSpace: "nowrap" }}>2,888元</Typography>
                                </Box> */}
                                <FormControl fullWidth hiddenLabel>
                                    <Select
                                        id="demo-simple-select"
                                        value={gameStatus}
                                        onChange={handleGameStatusChange}
                                    >
                                        <MenuItem value={0}>全部</MenuItem>
                                        <MenuItem value={4}>進行中與尚未開始</MenuItem>
                                        <MenuItem value={1}>進行中</MenuItem>
                                        <MenuItem value={2}>尚未開始</MenuItem>
                                        <MenuItem value={3}>已結束</MenuItem>
                                    </Select>
                                </FormControl>
                            </Stack>
                        </Grid>


                        {games != null && games.map(row => {
                            return <Grid item key={row.id} xs={12} sm={12} md={12}>
                                <Card variant="outlined" elevation={1} sx={{}}>
                                    <Stack direction="row" justifyContent="space-between" sx={{ pt: 1, pl: 1, pr: 1 }}>

                                        <Stack direction={"row"} spacing={0.5}>
                                            {row.icon != null && <img style={{ width: 52, height: 52 }} src={row.icon} />}
                                            <Stack>
                                                <Typography sx={{ ml: 0.5, color: grey[600] }} fontSize={14} >
                                                    第{row.num}場，免報名費
                                                </Typography>
                                                <Typography sx={{}} fontSize={24} >
                                                    {row.name}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                        <Stack>
                                            {/* <Typography sx={{ textAlign: "right" }} fontSize={16} >{row.year}第{row.num}場</Typography> */}
                                            <Stack spacing={0.2} direction={"row"}>
                                                <Chip color={getStatusColor(row.status)} size="medium" label={getStatus(row.status)}></Chip>
                                                {/* {row.status === 2 && row.in_grace && <Chip color="default" size='small' label={"建置期"}></Chip>} */}
                                            </Stack>
                                        </Stack>

                                    </Stack>
                                    <Divider variant="fullWidth"></Divider>
                                    <Grid container spacing={0} columns={18}>
                                        <Grid item key={row.id + "i1"} xs={6} sm={6} md={6} sx={{ bgcolor: theme.palette.mode === 'dark' ? grey[900] : grey[100] }}>
                                            <Box sx={{ justifyContent: "center", alignItems: "center", height: "100%" }}>
                                                <Box display={"flex"} sx={{ justifyContent: "center", alignItems: "center", height: "100%" }}>
                                                    <Box sx={{ textAlign: "center" }}>
                                                        {row.status != 1 && <>
                                                            <Typography sx={{ fontSize: fullScreen ? 14 : 18 }}>{getTitle(row)}</Typography>
                                                            <Typography sx={{ fontWeight: "bold", fontSize: fullScreen ? 48 : 88 }}>{getValue(row)}</Typography>
                                                        </>}

                                                        {row.status === 1 && <>
                                                            <Typography sx={{ fontSize: fullScreen ? 14 : 18 }}>{"倒數日"}</Typography>
                                                            <Typography sx={{ fontWeight: "bold", fontSize: fullScreen ? 68 : 88 }}>{dateDiffInDays(new Date(), toDate(row.start_ymd))}</Typography>
                                                        </>}

                                                        {/* {row.left != null && <Typography sx={{ fontSize: fullScreen ? 12 : 18, color: row.left.color === 0 ? red[600] : green[600] }}>{row.left.title}</Typography>} */}
                                                        {/* {row.left != null && <Typography sx={{ fontWeight: "bold", fontSize: fullScreen ? 48 : 88, color: row.left.color === 0 ? red[600] : green[600] }}>{row.left.data}</Typography>} */}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item key={row.id + "i3"} xs={12} sm={12} md={12}>
                                            <Box>
                                                <Box sx={{ m: 0, p: 1.2 }}>
                                                    <Typography variant='body2'>{row.short_desc}</Typography>
                                                    <Button onClick={e => showRule(row)} variant='contained' fullWidth color="inherit" size='small' disableElevation>詳細活動說明</Button>
                                                </Box>
                                                <Divider></Divider>
                                                <List dense={true} sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                                    {/* {(row.id === "1" || row.id === "2" || row.id === "3") && <> */}
                                                        <ListItem>
                                                            <ListItemAvatar>
                                                                <Avatar sx={{ width: 36, height: 36 }}>
                                                                    <ImageIcon />
                                                                </Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText primary="參加人數" secondary={
                                                                row.user_count + "人"

                                                            } />
                                                        </ListItem>
                                                        <Divider variant="middle" component="li" />
                                                    {/* </>} */}


                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar sx={{ width: 36, height: 36 }}>
                                                                <WorkIcon />
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary="活動日期" secondary={row.start_ymd + "－" + row.end_ymd} />
                                                    </ListItem>

                                                    {!(row.id === "1" || row.id === "2" || row.id === "3") && <>
                                                        <Divider variant="middle" component="li" />

                                                        <ListItem>
                                                            <ListItemAvatar>
                                                                <Avatar sx={{ width: 36, height: 36 }} >
                                                                    <BeachAccessIcon />
                                                                </Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText primary="獎勵" secondary={"累積個人收益，可按特定匯率兌換現金"} />
                                                        </ListItem>
                                                    </>}


                                                    {(row.id === "1" || row.id === "2" || row.id === "3") && <>
                                                        <Divider variant="middle" component="li" />

                                                        <ListItem>
                                                            <ListItemAvatar>
                                                                <Avatar sx={{ width: 36, height: 36 }} >
                                                                    <BeachAccessIcon />
                                                                </Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText primary="評分標準" secondary={row.win_rule} />
                                                        </ListItem>
                                                        <Divider variant="middle" component="li" />
                                                        <ListItem>
                                                            <ListItemAvatar>
                                                                <Avatar sx={{ width: 36, height: 36 }} >
                                                                    <Payment />
                                                                </Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText primary="獲勝獎金/獎品" secondary={row.money + "元"} />
                                                        </ListItem>
                                                    </>}
                                                </List>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Divider variant="fullWidth"></Divider>
                                    <Stack direction="row" spacing={0.1} fullWidth>
                                        <Button onClick={e => showDashboard(row.id, row.point, row.in_grace, row.status, row.end_ymd)} color="primary" elevation={5} fullWidth sx={{ borderRadius: 0 }} startIcon={<Assessment sx={{}} />} size='large' variant="contained">績效 & 統計</Button>
                                        {row != null && row.is_join ? <Button color="primary" elevation={5} fullWidth sx={{ borderRadius: 0 }} startIcon={<ContactMail sx={{}} />} size='large' variant='contained' onClick={e => play(row.id, row.end_ymd)}>我的操盤</Button>
                                            : <Button disabled={row.status === 3} color="primary" elevation={5} fullWidth sx={{ borderRadius: 0 }} startIcon={<ContactMail sx={{}} />} size='large' variant='contained' onClick={e => join(row)}>我要參加</Button>}
                                        {/* {row.is_join ? <Button color="inherit" elevation={5} fullWidth sx={{ borderRadius: 0 }} startIcon={<ContactMail sx={{}} />} size='large' variant='contained' onClick={e => play(row.id)}>我的操盤</Button> :
                                            <Button color="primary" elevation={5} fullWidth sx={{ borderRadius: 0 }} startIcon={<ContactMail sx={{}} />} size='large' variant='contained' onClick={e => join(row.id)}>我要參加</Button>} */}
                                    </Stack>
                                    {/* </Box> */}
                                </Card>
                            </Grid>
                        })}


                        {payGames != null && payGames.map(row => {
                            return <Grid item key={row.id} xs={12} sm={12} md={12}>
                                <Card variant="outlined" elevation={1} sx={{}}>
                                    {/* justifyContent="space-between" alignItems="center"  */}

                                    <Stack direction="row" justifyContent="space-between" sx={{ p: 1, }}>
                                        <Stack>

                                            <Typography sx={{ ml: 0.5, color: grey[600] }} fontSize={16} >
                                                第{row.num}場，報名費：NT${row.pay}
                                            </Typography>
                                            <Typography sx={{}} fontSize={30} >
                                                {row.name}
                                            </Typography>
                                        </Stack>
                                        <Stack>
                                            {/* <Typography sx={{ textAlign: "right" }} fontSize={16} >{row.year}第{row.num}場</Typography> */}
                                            <Stack spacing={0.2} direction={"row"}>

                                                <Chip color={getStatusColor(row.status)} size='small' label={getStatus(row.status)}></Chip>
                                            </Stack>
                                        </Stack>

                                    </Stack>
                                    <Divider variant="fullWidth"></Divider>
                                    <Grid container spacing={0} columns={18}>
                                        <Grid item key={row.id + "i1"} xs={6} sm={6} md={6} sx={{ bgcolor: theme.palette.mode === 'dark' ? grey[900] : grey[100] }}>
                                            <Box sx={{ justifyContent: "center", alignItems: "center", height: "100%" }}>
                                                <Box display={"flex"} sx={{ justifyContent: "center", alignItems: "center", height: "100%" }}>
                                                    <Box sx={{ textAlign: "center" }}>
                                                        <Typography sx={{ fontSize: fullScreen ? 14 : 18 }}>{getTitle(row)}</Typography>
                                                        <Typography sx={{ fontWeight: "bold", fontSize: fullScreen ? 48 : 88 }}>{getValue(row)}</Typography>
                                                        {/* {row.left != null && <Typography sx={{ fontSize: fullScreen ? 12 : 18, color: row.left.color === 0 ? red[600] : green[600] }}>{row.left.title}</Typography>} */}
                                                        {/* {row.left != null && <Typography sx={{ fontWeight: "bold", fontSize: fullScreen ? 48 : 88, color: row.left.color === 0 ? red[600] : green[600] }}>{row.left.data}</Typography>} */}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item key={row.id + "i3"} xs={12} sm={12} md={12}>
                                            <Box>
                                                <Box sx={{ m: 0, p: 1.2 }}>
                                                    <Typography variant='body2'>{row.short_desc}</Typography>
                                                    <Button onClick={e => showRule(row)} variant='contained' fullWidth color="inherit" size='small' disableElevation>詳細活動說明</Button>
                                                </Box>
                                                <Divider></Divider>
                                                <List dense={true} sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                                    {/* <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar sx={{ width: 36, height: 36 }}>
                                                                <ImageIcon />
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary="目前 / 最少參加人數" secondary={
                                                            row.user_count + "人 / " + row.min_count + "人"

                                                        } />
                                                    </ListItem>
                                                    <Divider variant="middle" component="li" /> */}
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar sx={{ width: 36, height: 36 }}>
                                                                <WorkIcon />
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary="活動日期" secondary={row.start_ymd + "－" + row.end_ymd} />
                                                    </ListItem>
                                                    <Divider variant="middle" component="li" />
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar sx={{ width: 36, height: 36 }} >
                                                                <BeachAccessIcon />
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary="評分標準" secondary={row.win_rule} />
                                                    </ListItem>
                                                    <Divider variant="middle" component="li" />
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar sx={{ width: 36, height: 36 }} >
                                                                <Payment />
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary="獲勝獎金/獎品" secondary={<div>{row.money_desc}</div>} />
                                                    </ListItem>

                                                </List>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Divider variant="fullWidth"></Divider>
                                    <Stack direction="row" spacing={0.1} fullWidth>
                                        <Button onClick={e => showDashboard(row.id, row.point, row.in_grace, row.status)} color="primary" elevation={5} fullWidth sx={{ borderRadius: 0 }} startIcon={<Assessment sx={{}} />} size='large' variant="contained">績效 & 統計</Button>
                                        {row != null && row.is_join ? <Button color="primary" elevation={5} fullWidth sx={{ borderRadius: 0 }} startIcon={<ContactMail sx={{}} />} size='large' variant='contained' onClick={e => play(row.id, row.end_ymd)}>我的操盤</Button>
                                            : <Button color="primary" elevation={5} fullWidth sx={{ borderRadius: 0 }} startIcon={<ContactMail sx={{}} />} size='large' variant='contained' onClick={e => join1(row)}>我要參加</Button>}
                                        {/* {row.is_join ? <Button color="inherit" elevation={5} fullWidth sx={{ borderRadius: 0 }} startIcon={<ContactMail sx={{}} />} size='large' variant='contained' onClick={e => play(row.id)}>我的操盤</Button> :
                                            <Button color="primary" elevation={5} fullWidth sx={{ borderRadius: 0 }} startIcon={<ContactMail sx={{}} />} size='large' variant='contained' onClick={e => join(row.id)}>我要參加</Button>} */}
                                    </Stack>
                                    {/* </Box> */}
                                </Card>
                            </Grid>
                        })}

                        {games != null && games.length === 0 && <Grid item key={"empty"} xs={12} sm={12} md={12}>
                            <Typography fullWidth sx={{ textAlign: "center" }}>沒有任何資料</Typography>
                        </Grid>}
                    </Grid>
                </Container>

            </Box >
        </>
    );
}