import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getId } from '../util/ID'

const options = [
    '刪除',
];

const ITEM_HEIGHT = 48;

export default function TransTableAction(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const menuClick = (value) => {
        if(value === "刪除") {
            del()
        }
    }

    const del = () => {
        let param = {
            sn: getId(),
            gid: props.gid,
            uid: localStorage.getItem("game_user_id"),
            phone: localStorage.getItem("game_phone"),
            name: localStorage.getItem("game_name"),
            id: props.row.id,
        }

        if (localStorage.getItem("game_lic") != null) {
            param['lic'] = localStorage.getItem("game_lic")
        }


        fetch("/game/v1/stock/order/del", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.status == 0) {
                        if (props.refresh != null) {
                            props.refresh()
                        }
                    } 
                }
            );

    }

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >
                {options.map((option) => (
                    <MenuItem key={option}  onClick={e => menuClick(option)}>
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}
