import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AlertD from '../util/AlertD';
import ActionRule from './ActionRule';
import { Stack, TextField, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

export default function DrawMoney(props) {
    const [open, setOpen] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);
    const [disableSend, setDisableSend] = React.useState(false);
    const [pin, setPin] = React.useState("");

    const [bankName, setBankName] = React.useState(null);
    const [branchName, setBranchName] = React.useState(null);
    const [account, setAccount] = React.useState(null);
    const [name, setName] = React.useState(null);
    const [money, setMoney] = React.useState(null);


    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };

    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);


    const draw = () => {
        if (bankName == null || bankName === "" || branchName == null ||
            branchName === "" || account == null || account === "" ||
            name == null || name === ""
        ) {
            setAlertMsg("請確實輸入銀行帳號")
            setShowAlert(true)
        } else {
            let param = {
                uid: localStorage.getItem("game_user_id"),
                phone: localStorage.getItem("game_phone"),
                name: localStorage.getItem("game_name"),
                bank: bankName,
                branch: branchName,
                account: account,
                receiver: name
            }

            fetch("/game/v1/draw/new", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result != null) {
                            if (result.status === 0) {
                                if (props.refresh != null) props.refresh()
                                handleClose()
                            } else if (result.status === -2 || result.status === -3) {
                                setAlertMsg("找不到您的累積盈餘")
                                setShowAlert(true)
                            } else if (result.status === -4) {
                                setAlertMsg("累積盈餘不夠兌換現金")
                                setShowAlert(true)
                            } else if (result.status === -5) {
                                setAlertMsg("手機號碼尚未驗證")
                                setShowAlert(true)
                            }
                        }
                    }
                );
        }
    }
    return (
        <div>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}

            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    提領獎金
                </DialogTitle>
                <DialogContent sx={{ p: 3 }}>
                    <Typography fontSize={12}>1. 大約需要5-10個工作日才能完成轉帳</Typography>
                    <Typography fontSize={12}>2. 請詳細檢查帳戶是否填寫正確，填寫錯誤不會再補發獎金。</Typography>
                    <Stack sx={{ mt: 1 }} spacing={2}>
                        <TextField
                            label="銀行名稱"
                            id="bankName"
                            variant="outlined"
                            fullWidth
                            size="small"
                            value={bankName}
                            onChange={(event) => {
                                setBankName(event.target.value);
                            }}
                        />

                        <TextField
                            label="分行名稱"
                            id="branchName"
                            variant="outlined"
                            fullWidth
                            size="small"
                            value={branchName}
                            onChange={(event) => {
                                setBranchName(event.target.value);
                            }}
                        />

                        <TextField
                            label="銀行帳號"
                            id="account"
                            variant="outlined"
                            fullWidth
                            size="small"
                            value={account}
                            onChange={(event) => {
                                setAccount(event.target.value);
                            }}
                        />

                        <TextField
                            label="姓名"
                            id="name"
                            variant="outlined"
                            fullWidth
                            size="small"
                            value={name}
                            onChange={(event) => {
                                setName(event.target.value);
                            }}
                        />

                        {/* <TextField
                            label="獎金"
                            id="money"
                            variant="outlined"
                            fullWidth
                            size="small"
                            value={money}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">元</InputAdornment>,
                            }}
                            onChange={(event) => {
                                setMoney(event.target.value);
                            }}
                        /> */}


                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button disableElevation fullWidth onClick={handleClose} color="inherit" variant="contained">關閉</Button>
                    <Button disableElevation fullWidth onClick={draw} color="inherit" variant="contained">確定</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
