import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { getId } from '../util/ID'
import AlertD from '../util/AlertD';
import { red } from '@mui/material/colors';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TransTables from './TransTable';
import { CarCrash, Fingerprint, Refresh } from '@mui/icons-material';
import PersonalCard1 from './PersonalCard1';
import BuySellDialog from './BuySellDialog';
import StorageTable from './StorageTable';
import SellDialog from './SellDialog';
import AutoTable from '../auto/AutoTable';
import AddAutoDialog from '../auto/AddAutoDialog';
// import { Adsense } from '@ctrl/react-adsense';
import AssetData from './AssetData';

export default function PersonalDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);
    const [openSetting1, setOpenSetting1] = React.useState(false);
    const [result, setResult] = React.useState(null)

    const get = () => {
        let param = {
            sn: getId(),
            gid: props.gid,
            uid: localStorage.getItem("game_user_id"),
            phone: localStorage.getItem("game_phone"),
            name: localStorage.getItem("game_name"),
        }

        if (localStorage.getItem("game_lic") != null) {
            param['lic'] = localStorage.getItem("game_lic")
        }


        fetch("/game/v1/stock/get", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        if (result.status == 0) {
                            setResult(result.personal)
                        } else if (result.status === -1) {
                            setAlertMsg("手機與姓名無法匹配。")
                            setShowAlert(true)
                        }
                    } else {
                        setAlertMsg("很抱歉，伺服器發生錯誤，請稍後再嘗試")
                        setShowAlert(true)
                    }
                }
            );

    }

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };

    useEffect(() => {
        if (props.open) {
            get()
            setOpen(true)
        } else setOpen(false)
    }, [props.open]);


    return (
        <div>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            {openSetting ? <BuySellDialog refresh={get} gid={props.gid} open={openSetting} close={setOpenSetting}></BuySellDialog> : null}
            {openSetting1 ? <AddAutoDialog result={result} refresh={get} gid={props.gid} open={openSetting1} close={setOpenSetting1}></AddAutoDialog> : null}


            <Dialog
                fullScreen
                fullWidth={true}
                // maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogContent sx={{ p: 2 }}>

                    <Box display={"flex"} sx={{ mb: 1 }}>
                        <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>個人摘要</Typography>
                    </Box>
                    <PersonalCard1 result={result} />
                    
                    {result != null && <Box sx={{ mb: 5 }}>
                        <AssetData result={result} />
                    </Box>}

                    {/* <Box sx={{p:0.1}}>
                    <Adsense
                        client="ca-pub-8613274553211553"
                        slot="1654340287"
                        style={{ display: 'block' }}
                        layout="in-article"
                        format="fluid"
                    />
                    </Box> */}

                    <Box display={"flex"} sx={{ mt: 2, mb: 1 }}>
                        <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>交易紀錄</Typography>
                        <Button onClick={e => setOpenSetting(true)} color="inherit" startIcon={<Fingerprint />} sx={{ ml: 1 }} variant='contained' disableElevation>手動買進</Button>
                        <Button onClick={e => get()} color="inherit" startIcon={<Refresh />} sx={{ ml: 1 }} variant='contained' disableElevation>重新整理</Button>
                    </Box>
                    <TransTables endYMD={props.endYMD} wid={localStorage.getItem("game_user_id")} gid={props.gid} result={result} refresh={get} action={true} />

                    <Divider sx={{ mt: 4, mb: 2 }}></Divider>

                    <Box display={"flex"} sx={{ mb: 1 }}>
                        <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>庫存</Typography>
                    </Box>
                    <StorageTable endYMD={props.endYMD} wid={localStorage.getItem("game_user_id")} refresh={get} result={result} gid={props.gid} action={true} />

                    <Divider sx={{ mt: 4, mb: 2 }}></Divider>
                    <Box display={"flex"} sx={{ mb: 1 }}>
                        <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>自動交易</Typography>
                        <Button onClick={e => setOpenSetting1(true)} color="inherit" startIcon={<CarCrash />} sx={{ ml: 1 }} variant='contained' disableElevation>設定</Button>
                    </Box>
                    <AutoTable refresh={get} result={result} gid={props.gid} action={true} />

                </DialogContent>
                <DialogActions>
                    <Button disableElevation variant='contained' color="inherit" fullWidth onClick={handleClose}>關閉</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
