import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, BarChart, CandlestickChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { green, orange, purple, red } from '@mui/material/colors';
// import { Adsense } from '@ctrl/react-adsense';

echarts.use(
    [VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, CanvasRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent, BarChart]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        // marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        // width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));



export default function DetailPrice(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [result, setResult] = React.useState(null);
    const [open, setOpen] = React.useState(false);

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
    };

    useEffect(() => {
        if (props.open) {
            setOpen(true)
            if (props.gid != null && props.wid != null && props.sid != null) fetchData()
        } else setOpen(false)
    }, [props.open]);

    const getLine = (vv) => {
        if (vv.storage != null && props.endYMD != null)
            return {
                lineStyle: {
                    // color: "#000000"
                },
                symbol: "none",
                data: [{
                    label: {
                        position: "insideStartTop",
                        formatter: '{b}：{c}元',
                        fontSize: 16
                    },
                    name: '庫存成本',
                    yAxis: vv.storage
                }, {
                    label: {
                        // position:"insideStartTop",
                        formatter: '比賽結束日',
                        fontSize: 16
                    },
                    name: "結束日",
                    xAxis: props.endYMD
                }]
            }
        else if (props.endYMD != null) {
            return {
                lineStyle: {
                    // color: "#000000"
                },
                symbol: "none",
                data: [{
                    label: {
                        // position:"insideStartTop",
                        formatter: '比賽結束日',
                        fontSize: 16
                    },
                    name: "結束日",
                    xAxis: props.endYMD
                }]
            }
        }
        else if (vv.storage != null)
            return {
                lineStyle: {
                    // color: "#000000"
                },
                symbol: "none",
                data: [{
                    label: {
                        position: "insideStartTop",
                        formatter: '{b}：{c}元',
                        fontSize: 16
                    },
                    name: '庫存成本',
                    yAxis: vv.storage
                }]
            }
        else return null

    }

    function genoption2(vv) {
        let k = {
            animation: false,
            tooltip: {
                show: true
            },
            // legend: {
            //     show: true
            // },
            grid: [{
                top: 40,
                left: 0,
                right: 10,
                bottom: 0,
                containLabel: true
            }],
            axisPointer: {
                show: true
            },
            xAxis: [{
                type: 'category',
                boundaryGap: false,
                data: vv.prices.map(row => row.d),
            }],
            yAxis: [{
                scale: true,
                type: 'value',
                position: 'left',
                show: true,
                axisLabel: {
                    inside: true,
                    margin: 0,
                    fontWeight: "bold",
                    fontSize: 12
                },
            }],
            dataZoom: [
                {
                    type: 'inside',
                    start: 0,
                    end: 100
                }
            ],
            series: [
                {
                    name: "股價",
                    type: 'candlestick',
                    data: vv.prices.map(v => { return [v.o, v.p, v.l, v.h] }),
                    itemStyle: {
                        color: "#d41c1c",
                        color0: '#65b206',
                        borderColor: "#d41c1c",
                        borderColor0: '#65b206'
                    },
                    selectedMode: 'single',
                    markPoint: vv.trans != null ? {
                        data: vv.trans.map(v => {
                            return {
                                value: v.tp === 0 ? "買" : "賣",
                                symbolOffset: [0, -45],
                                symbol: "circle",
                                symbolSize: 24,
                                coord: [v.ymd, v.price],
                                label: {
                                    show: true,
                                    fontSize: 10,
                                    color: "#FFFFFF"
                                },
                                itemStyle: {
                                    color: v.tp === 0 ? orange[500] : purple[500]
                                }
                            }
                        })
                    } : null,
                    markLine: getLine(vv),
                }
            ]
        };



        return k;
    }


    const fetchData = () => {
        let param = {
            gid: props.gid,
            wid: props.wid,
            sid: props.sid,
        }


        fetch("/game/v1/stock/detail", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null && result.status === 0) {
                        setResult(result.detail_prices)
                    }
                }
            );
    }

    useEffect(() => {
        if (result != null) {
            setOption(genoption2(result))
        }
    }, [result]);

    const getColor = () => {
        if (result != null && result.price != null) {
            if (result.price.i > 0) return "red"
            else if (result.price.i < 0) return "green"
            else return null
        } else return null
    }

    const getInv = () => {
        if (result != null && result.price != null) {
            let k = ((result.price.i * 100) / (result.price.p - result.price.i)).toFixed(2)
            if (result.price.i > 0) return "▲" + result.price.i + '元' + "(" + k + "%)"
            else if (result.price.i < 0) return "▼" + result.price.i + '元' + "(" + k + "%)"
            else return result.price.i + '元' + "(" + k + "%)"
        } else return '▲0元(0%)'
    }

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
        >
            <DialogTitle sx={{ p: 0 }}>
            </DialogTitle>
            <DialogContent sx={{ pl: 1, pr: 1 }}>
                {result != null && <Box display={'flex'}>
                    <Stack direction='row' spacing={0.5} sx={{ flex: 1, p: 0, pl: 0, pt: 0.5, alignSelf: 'center' }} >
                        <Typography sx={{ fontSize: 30, fontWeight: 'bold', alignSelf: 'center' }} >{result.company.short_name}</Typography>
                        <Typography variant="caption" sx={{ fontWeight: 'normal' }} >{result.company.stock_no}</Typography>
                    </Stack>
                    <Stack direction='row' spacing={0.5} sx={{ pr: 1, alignSelf: 'center' }} >
                        <Typography color={getColor()} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{result.price != null ? result.price.p : "-"}</Typography>
                        <Typography color={getColor()} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{getInv()}</Typography>
                    </Stack>
                </Box>}

                <Divider sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main }}
                    style={{
                        border: "none",
                        height: 0.5,
                        margin: 0,
                    }} />
                {/* <Box sx={{ p: 0.1 }}>
                    <Adsense
                        client="ca-pub-8613274553211553"
                        slot="8074842582"
                        style={{ display: 'block' }}
                        layout="in-article"
                        format="fluid"
                    />
                </Box> */}


                <Typography variant='h5' sx={{ mt: 8 }}>買賣進出圖</Typography>
                {option != null ?
                    <ReactEChartsCore
                        theme={theme.palette.mode}
                        echarts={echarts}
                        option={option}
                        notMerge={true}
                        lazyUpdate={false}
                        style={{ height: '60vh', marginTop: 0 }}
                    /> : null}


            </DialogContent>
            <DialogActions>
                <Button fullWidth disableElevation variant="contained" color="inherit" onClick={handleClose}>關閉</Button>
            </DialogActions>
        </Dialog>
    );
}

