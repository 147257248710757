import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { getId } from '../util/ID'
import AlertD from '../util/AlertD';
import { red } from '@mui/material/colors';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SearchCompany from './SearchCompany';
import WatchTable1 from './WatchTable1';
import AssetData from './AssetData';

export default function WatchDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);

    const [price, setPrice] = React.useState(null);
    const [volume, setVolume] = React.useState(null);
    const [sid, setSID] = React.useState(null);
    const [rPrice, setRPrice] = React.useState(null);
    const [note, setNote] = React.useState(null);
    const [result, setResult] = React.useState(null);

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };

    const getWatch = () => {
        let param = {
            wid: props.wid,
        }


        fetch("/game/v1/watch/get1", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null && result.status === 0) {
                        setResult(result)
                    }
                }
            );
    }

    useEffect(() => {
        if (props.wid != null) {
            getWatch()
        }
    }, [props.wid]);

    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);


    return (
        <div>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}

            <Dialog
                fullScreen
                fullWidth={true}
                maxWidth={"lg"}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography fontWeight={"bold"} variant='h5' >{props.name}{"歷史戰績"}</Typography>
                </DialogTitle>
                <DialogContent sx={{ p: 0 }}>
                    {result != null && result.money_trend != null && <AssetData moneyTrend={result.money_trend}></AssetData>}
                    <WatchTable1 result={result} />
                </DialogContent>
                <DialogActions>
                    <Button fullWidth color="inherit" variant='contained' onClick={handleClose}>關閉</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
