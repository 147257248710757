import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { getId } from '../util/ID'
import AlertD from '../util/AlertD';
import BuySellDialog from './BuySellDialog';
import LeaderBoardTable from './LeaderBoardTable';
// import { Adsense } from '@ctrl/react-adsense';
import StatisticsShortTable from './StatisticsShortTable';


export default function LeaderBoard(props) {

    const [open, setOpen] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);
    const [openSetting1, setOpenSetting1] = React.useState(false);
    const [result, setResult] = React.useState(null)
    const [point, setPoint] = React.useState(0)

    const get = () => {
        let param = {
            sn: getId(),
            gid: props.gid,
            uid: localStorage.getItem("game_user_id"),
            phone: localStorage.getItem("game_phone"),
            name: localStorage.getItem("game_name"),
        }

        if (localStorage.getItem("game_lic") != null) {
            param['lic'] = localStorage.getItem("game_lic")
        }


        fetch("/game/v1/leaderboard/get1", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setResult(result)
                        setPoint(result.point)
                    }
                }
            );

    }

    const refreshPoint = () => {
        if (!props.inGrace && point >= props.dPoint)
            setPoint(point - props.dPoint)
        // if (localStorage.getItem("game_user_id") != null) {
        //     let param = {
        //         sn: getId(),
        //         uid: localStorage.getItem("game_user_id"),
        //         phone: localStorage.getItem("game_phone"),
        //         name: localStorage.getItem("game_name"),
        //     }

        //     if (localStorage.getItem("game_lic") != null) {
        //         param['lic'] = localStorage.getItem("game_lic")
        //     }


        //     fetch("/game/v1/point/get", {
        //         method: "POST",
        //         headers: {
        //             "Content-Type": "application/json"
        //         },
        //         body: JSON.stringify(param)
        //     })
        //         .then(res => res.json())
        //         .then(
        //             result => {
        //                 if (result != null && result.status === 0 && result.my_point != null) {
        //                     setPoint(result.my_point.total)
        //                 }
        //             }
        //         );
        // }
    }

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
    };



    useEffect(() => {
        if (props.open) {
            get()
            setOpen(true)
        } else setOpen(false)
    }, [props.open]);

    return (
        <div>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            {openSetting ? <BuySellDialog refresh={get} gid={props.gid} open={openSetting} close={setOpenSetting}></BuySellDialog> : null}

            <Dialog
                fullScreen
                fullWidth={true}
                // maxWidth={'md'}
                open={open}
                onClose={handleClose}

            >
                {/* <DialogTitle id="alert-dialog-title">
                    {"個人操盤"}
                </DialogTitle> */}
                <DialogContent sx={{ p: 2 }}>

                    <Box sx={{ mb: 1 }}>
                        <Typography sx={{ fontSize: 26, fontWeight: "bold" }}>績效排行榜與統計表</Typography>
                        {/* {props.inGrace && <Typography sx={{ fontSize: 18, }}>現在可查看排行榜與統計表。</Typography>}
                        {props.gameStatus1 === 3 && <Typography sx={{ fontSize: 18, }}>比賽已結束可查看排行榜與統計表。</Typography>}
                        {!(props.gameStatus1 === 3 || props.inGrace) && <Typography sx={{ fontSize: 18, }}>此刻無法查看排行榜與統計表。</Typography>} */}

                        {/*                         
                        {props.inGrace || props.gameStatus1 === 3 ? <Typography sx={{ fontSize: 18, }}>現在查看排行榜與統計表不需消耗點數。您目前的點數為{point}點。</Typography> :
                            <Typography sx={{ fontSize: 18, }}>查看一次排行榜與統計表將消耗{props.dPoint}點。您目前的點數為{point}點。</Typography>} */}
                    </Box>

                    {/* <Box sx={{p:0.1}}>
                        <Adsense
                            client="ca-pub-8613274553211553"
                            slot="5875019844"
                            style={{ display: 'block' }}
                            layout="in-article"
                            format="fluid"
                        />
                    </Box> */}
                    <Typography sx={{ fontSize: 18, fontWeight: "bold", mt: 4 }}>庫存統計表</Typography>
                    {result != null && result.statics != null && <StatisticsShortTable gameStatus1={props.gameStatus1} label="庫存" inGrace={props.inGrace} refreshPoint={refreshPoint} refresh={get} count={result.statics.a1} result={result.statics.a} gid={props.gid} dPoint={props.dPoint} point={result.point} />}

                    <Typography sx={{ fontSize: 18, fontWeight: "bold", mt: 4 }}>預約單統計表</Typography>
                    {result != null && result.statics != null && <StatisticsShortTable gameStatus1={props.gameStatus1} label="預約單" inGrace={props.inGrace} refreshPoint={refreshPoint} refresh={get} count={result.statics.b1} result={result.statics.b} gid={props.gid} dPoint={props.dPoint} point={result.point} />}

                    {/* <Divider sx={{mt:6, mb:3}}></Divider>
                    <Box sx={{ mb: 1 }}>
                        <Typography sx={{ fontSize: 26, fontWeight: "bold" }}>績效排行榜</Typography>
                        {props.inGrace || props.gameStatus1 === 3 ? <Typography sx={{ fontSize: 18, }}>現在查看交易紀錄不需消耗點數。您目前的點數為{point}點。</Typography> :
                            <Typography sx={{ fontSize: 18, }}>查看一次交易記錄將消耗{props.dPoint}點。您目前的點數為{point}點。</Typography>}
                    </Box> */}

                    <Typography sx={{ fontSize: 18, fontWeight: "bold", mt: 4 }}>績效排行榜</Typography>
                    {result != null && <LeaderBoardTable endYMD={props.endYMD} gameStatus1={props.gameStatus1} inGrace={props.inGrace} refreshPoint={refreshPoint} refresh={get} result={result.roi} gid={props.gid} dPoint={props.dPoint} point={result.point} />}
                </DialogContent>
                <DialogActions>
                    <Button disableElevation variant='contained' color="inherit" fullWidth onClick={handleClose}>關閉</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
