import React, { useEffect, useRef } from 'react';
// import { createTheme, ThemeProvider } from '@mui/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Routes from './Routes'
import ColorModeContext from './util/ColorMode'
// import {fpPromise} from './util/BasicInfo'
import { getId, getLic } from './util/ID'
import * as echarts from 'echarts/core';
import {EchartTheme, EchartDarkTheme} from './util/EChartTheme'
import { orange, red, green } from '@mui/material/colors';
echarts.registerTheme('light', EchartTheme);
echarts.registerTheme('dark', EchartDarkTheme);

function MobileApp() {
    // const [mode, setMode] = React.useState('light');
    const [mode, setMode] = React.useState(localStorage.getItem("colormode") != null ? localStorage.getItem("colormode") : 'light');

    useEffect(() => {
    }, []);


    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => {
                    let curMode = prevMode === 'light' ? 'dark' : 'light'
                    localStorage.setItem("colormode", curMode)
                    return curMode
                });
            },
        }),
        [],
    );

    // const theme = React.useMemo(
    //     () =>
    //         createTheme({
    //             palette: {
    //                 neutral: {
    //                     main: '#ebdbd5',
    //                     contrastText: '#fff',
    //                 },
    //                 primary: {
    //                     main: "#FFC75F"
    //                 },
    //                 secondary: {
    //                     main: "#fbfbfb"
    //                 },
    //                 mode: mode
    //             },
    //         }),
    //     [mode],
    // );

    const theme = React.useMemo(
        () =>
            createTheme({
                components: {
                    MuiButton: {
                          styleOverrides: {
                            root: ({ ownerState }) => ({
                              ...(ownerState.variant === 'contained' &&
                                ownerState.color === 'error' && {
                                  backgroundColor: red[400],
                                  color: '#fff',
                                }),...(ownerState.variant === 'contained' &&
                                ownerState.color === 'success' && {
                                  backgroundColor: green[400],
                                  color: '#fff',
                                }),
                            }),
                          },
                    },
                },
                palette: {
                    neutral: {
                        main: red[900],
                        contrastText: '#fff',
                    },
                    primary: {
                        main: "#344157"// "#"
                    },
                    secondary: {
                        main: "#f2f2f2"
                    },

                    mode: mode
                },
            }),
        [mode],
    );

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <Routes />
            </ThemeProvider>
        </ColorModeContext.Provider>
    );

}

export default MobileApp;
