import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, BarChart, CandlestickChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { green, orange, purple, red } from '@mui/material/colors';
// import { Adsense } from '@ctrl/react-adsense';
import { priceWord } from '../util/Money'

echarts.use(
    [VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, CanvasRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent, BarChart]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        // marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        // width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));



export default function AssetData(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [option, setOption] = React.useState(null);


    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


    function genoption2(vv) {
        let k = {
            animation: false,
            tooltip: {
                show: true
            },
            grid: [{
                top: 20,
                left: 0,
                right: 20,
                bottom: 0,
                containLabel: true
            }],
            axisPointer: {
                show: true
            },
            xAxis: [{
                type: 'category',
                boundaryGap: false,
                data: vv.map(row => row.ymd),
            }],
            yAxis: [{
                scale: true,
                type: 'value',
                position: 'left',
                show: true,
                axisLabel: {
                    inside: true,
                    margin: 0,
                    fontWeight: "bold",
                    fontSize: 12
                },
            }],
            dataZoom: [
                {
                    type: 'inside',
                    start: 0,
                    end: 100
                }
            ],
            series: [
                {
                    name: "累積收益",
                    type: 'line',
                    data: vv.map(v => v.money),
                    // selectedMode: 'single',
                    symbol: "none",
                    endLabel: {
                        show : true,
                        formatter: function (value, index) {
                            return "累積收益："+ priceWord(value.value)
                        },
                        offset:[-130, -15],
                        fontSize:14,
                    }
                    
                }
            ],
            // visualMap: {
            //     show: false,
            //     type: 'piecewise',
            //     pieces: [
            //         { lt : 0, color: green[300] },
            //         { gte: 0, color: red[300] },
            //     ]
            // },            
        };



        return k;
    }


    useEffect(() => {
        if (props.result != null && props.result.asset != null) {
            setOption(genoption2(props.result.asset))
        } else if (props.moneyTrend != null) {
            setOption(genoption2(props.moneyTrend))
        }
    }, []);

    return (
        <>
            {option != null ?
                <ReactEChartsCore
                    theme={theme.palette.mode}
                    echarts={echarts}
                    option={option}
                    notMerge={true}
                    lazyUpdate={false}
                    style={{ height: '40vh', marginTop: 0 }}
                /> : null}
        </>
    );
}

