import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import AlertD from '../util/AlertD';
import AllStrageRules from '../strage/AllStrageRules'

export default function RuleDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);

    const [nowRows, setNowRows] = React.useState(localStorage.getItem(props.name) != null ? JSON.parse(localStorage.getItem(props.name)) : [])


    const join = () => {
        localStorage.setItem(props.name, JSON.stringify(nowRows)) 
        if(props.updateRuleCount != null) props.updateRuleCount()
        
        setOpen(false);
        if (props.close != null) props.close(false)        
    }

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
    };


    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);


    const updateRule = (id, data, enabled) => {
        if (enabled) {
            const rules = nowRows.filter(item => item.id !== id);
            const finalRules = rules.concat(data)
            setNowRows(finalRules)
        } else {
            const rules = nowRows.filter(item => item.id !== id)
            setNowRows(rules)
        }
    };

    return (
        <div>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}

            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >

                <DialogContent sx={{ p: 1 }}>
                    <AllStrageRules updateRule={updateRule} rows={nowRows} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>取消</Button>
                    <Button onClick={join} autoFocus>
                        確定
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
