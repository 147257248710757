import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TransTableAction from './TransTableAction';
import { green, red } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import DetailPrice from './DetailPrice';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontSize: 16,
        whiteSpace: "nowrap"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
        whiteSpace: "nowrap"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function TransTables(props) {
    const [openSetting, setOpenSetting] = React.useState(false);
    const [sid, setSid] = React.useState(null);
    const [endYMD, setEndYMD] = React.useState(null);

    const getStatus = (row) => {
        let m = "-手動"
        if (row.req != null && row.req.from != null && row.req.from === 1) m = "-自動化"
        if (row.status === 0) return "尚未成交" + m
        else if (row.status === 1) return "賀成交" + m
        else if (row.status === 2) {
            if (row.error_code === -1)
                return "帳戶資金不足" + m
            if (row.error_code === -3)
                return "資金帳戶有問題" + m
            else if (row.error_code === -7)
                return "庫存無此股票" + m
            else if (row.error_code === -8)
                return "庫存張數不足" + m
            else if (row.error_code === -10)
                return "處置股無法買"

        } else return ""
    }

    const getActionColor = (row) => {
        if (row.status === 0) return "inherit"
        else if (row.status === 1) return "inherit"
        else if (row.status === 2) {
            if (row.error_code === -1 || row.error_code === -3)
                return "error"
            else if (row.error_code === -7 || row.error_code === -8)
                return "error"
        } else return "inherit"
    }

    const getColor = (row) => {
        if (row != null && row.price != null) {
            if (row.price.i > 0) return red[400]
            else if (row.price.i < 0) return green[400]
            else return null
        } else return null
    }

    const getInv = (row) => {
        if (row != null && row.price != null) {
            let pp = ((row.price.i * 100) / (row.price.p - row.price.i)).toFixed(1)

            if (row.price.i > 0) return "▲" + row.price.i + ' (' + pp + '%)'
            else if (row.price.i < 0) return "▼" + row.price.i + ' (' + pp + '%)'
            else return row.price.i + '元' + ' (' + pp + '%)'
        } else return '▲0元'
    }

    const getROI = (row) => {
        if (row.req.tp === 0) {
            return "-"
        } else if (row.tran.cost != 0) {
            let pp = row.req.price * (1 - 0.003 - 0.001425)
            return (((pp / row.tran.cost) - 1) * 100).toFixed(2) + "%"
        } else return "-"
    }

    const getROIColor = (row) => {
        let pp = row.req.price * (1 - 0.003 - 0.001425)
        if (pp > row.tran.cost) {
            return "error"
        } else if (pp < row.tran.cost) {
            return "success"
        } else return "inherit"
    }

    const openDetail = (row) => {
        setSid(row.company.stock_no)
        setOpenSetting(true)
    }

    return (
        <>
            {openSetting ? <DetailPrice endYMD={props.endYMD} gid={props.gid} sid={sid} wid={props.wid} open={openSetting} close={setOpenSetting}></DetailPrice> : null}

            <TableContainer component={Paper}>
                <Table sx={{}} size="small" >
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">日期</StyledTableCell>
                            <StyledTableCell align="left">股票</StyledTableCell>
                            <StyledTableCell align="left">類股</StyledTableCell>
                            <StyledTableCell align="left">買/賣</StyledTableCell>
                            <StyledTableCell align="center">狀態</StyledTableCell>
                            <StyledTableCell align="center">價格</StyledTableCell>
                            <StyledTableCell align="center">股數</StyledTableCell>
                            <StyledTableCell align="center">報酬率</StyledTableCell>
                            <StyledTableCell align="center">備註</StyledTableCell>
                            {props.action && <StyledTableCell align="center">動作</StyledTableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.result != null && props.result.pre_order.map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell align="center" onClick={e => openDetail(row) }>{row.ymd}</StyledTableCell>

                                {row.company != null ?<StyledTableCell  onClick={e => openDetail(row) }  >
                                    {row.company.short_name} {row.company.stock_no}

                                    <Stack direction='row' spacing={0.5} sx={{ pr: 1, alignSelf: 'center' }} >
                                        <Typography color={getColor(row)} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }} >{row.price != null ? row.price.p : "-"}</Typography>
                                        <Typography color={getColor(row)} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }} >{getInv(row)}</Typography>
                                    </Stack>
                                </StyledTableCell>:<StyledTableCell>-</StyledTableCell>}
                                {row.company != null ?<StyledTableCell sx={{}}  onClick={e => openDetail(row) }>
                                    {row.company.inductry}
                                </StyledTableCell>:<StyledTableCell sx={{}}  onClick={e => openDetail(row) }>
                                    -
                                </StyledTableCell>}

                                <StyledTableCell align="center"  onClick={e => openDetail(row) } >
                                    <Avatar sx={{ bgcolor: row.req.tp === 0 ? red[600] : green[600] }} variant="rounded">
                                        {row.req.tp === 0 ? "買" : "賣"}
                                    </Avatar>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <Button  onClick={e => openDetail(row) } fullWidth disableElevation color={getActionColor(row)} variant="text">
                                        {getStatus(row)}</Button> </StyledTableCell>
                                <StyledTableCell align="center"  onClick={e => openDetail(row) } >{row.req.price}元</StyledTableCell>
                                <StyledTableCell align="center" onClick={e => openDetail(row) } >{row.req.volume}股</StyledTableCell>
                                <StyledTableCell align="center">{"-"}</StyledTableCell>
                                <StyledTableCell align="center">{row.req.note}</StyledTableCell>
                                {props.action && <StyledTableCell align="center">
                                    <TransTableAction gid={props.gid} row={row} refresh={props.refresh} />
                                </StyledTableCell>}
                            </StyledTableRow>
                        ))}
                        {props.result != null && props.result.order.map((row) => (
                            <StyledTableRow key={row.id}  onClick={e => openDetail(row) }>
                                <StyledTableCell align="center">{row.ymd}</StyledTableCell>
                                {/* <StyledTableCell align="center">{row.company.short_name}({row.company.stock_no})</StyledTableCell> */}

                                {row.company != null ? <StyledTableCell  >
                                    {row.company.short_name} {row.company.stock_no}

                                    <Stack direction='row' spacing={0.5} sx={{ pr: 1, alignSelf: 'center' }} >
                                        <Typography color={getColor(row)} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }} >{row.price != null ? row.price.p : "-"}</Typography>
                                        <Typography color={getColor(row)} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }} >{getInv(row)}</Typography>
                                    </Stack>
                                </StyledTableCell>:<StyledTableCell  >-</StyledTableCell>}
                                {row.company != null ?<StyledTableCell sx={{}} >
                                    {row.company.inductry}
                                </StyledTableCell>:<StyledTableCell sx={{}} >-</StyledTableCell>}

                                <StyledTableCell align="center">
                                    <Avatar sx={{ bgcolor: row.req.tp === 0 ? red[600] : green[600] }} variant="rounded">
                                        {row.req.tp === 0 ? "買" : "賣"}
                                    </Avatar>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <Button fullWidth disableElevation color={getActionColor(row)} variant="contained">
                                        {getStatus(row)}</Button> </StyledTableCell>
                                <StyledTableCell align="center">{row.req.price}元</StyledTableCell>
                                <StyledTableCell align="center">{row.req.volume}股</StyledTableCell>
                                <StyledTableCell align="center">
                                    <Button fullWidth disableElevation color={getROIColor(row)} variant="text">
                                        {getROI(row)}</Button>
                                </StyledTableCell>
                                <StyledTableCell align="center">{row.req.note}</StyledTableCell>
                                {props.action && <StyledTableCell align="center">
                                    {/* {row.status === 0 && <TransTableAction />} */}
                                </StyledTableCell>}
                            </StyledTableRow>
                        ))}
                        {props.result != null && props.result.pre_order != null &&
                            props.result.pre_order.length === 0 &&
                            props.result.order != null && props.result.order.length === 0 &&
                            <StyledTableRow key={"empty0"}>
                                <StyledTableCell align="center" colSpan={props.action ? 7 : 6}>{"無任何資料"}</StyledTableCell></StyledTableRow>}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
