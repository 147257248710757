import React, { useEffect, useRef } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';

export default function ActionRule1(props) {

    return (
        <div>
            <Typography sx={{ mt: 2, fontSize: 16, fontWeight: "bold" }}>
                活動內容
            </Typography>
            <Typography sx={{ fontSize: 16 }}>
                {props.game.short_desc}
                {/* 透過系統中最簡單的買進/賣出個股操作來實現報酬率，最終結算時將根據收益率由高到低排序，依據活動獎勵辦法發放獎金或獎品。 */}
            </Typography>

            <Typography sx={{ mt: 2, fontSize: 16, fontWeight: "bold" }}>
                最少報名人數
            </Typography>
            <Typography sx={{ fontSize: 16 }}>
                {props.game.min_count != null ? props.game.min_count + "人。" : "無。"}
            </Typography>

            <Typography sx={{ mt: 2, fontSize: 16, fontWeight: "bold" }}>
                報名費
            </Typography>
            <Typography sx={{ fontSize: 16 }}>
                {props.game.pay === 0 ? "免費報名。" : props.game.pay + "元。統一使用信用卡支付。若報名人數未達最低要求，報名費將全額刷退。"}
            </Typography>

            <Typography sx={{ mt: 2, fontSize: 16, fontWeight: "bold" }}>
                報名資格
            </Typography>
            <Typography sx={{ fontSize: 16 }}>
                年滿18歲，確實填寫個人基本資料後，同意遊戲規則，都可報名參加。
            </Typography>

            <Typography sx={{ mt: 2, fontSize: 16, fontWeight: "bold" }}>交易標的</Typography>
            <Typography sx={{ fontSize: 16 }}>
                系統上有的臺灣上市、上櫃股票、ETF
            </Typography>


            <Typography sx={{ mt: 2, fontSize: 16, fontWeight: "bold" }}>
                競賽期間
            </Typography>
            <Typography sx={{ fontSize: 16 }}>
                {props.game.start_ymd} - {props.game.end_ymd}
            </Typography>


            <Typography sx={{ mt: 2, fontSize: 16, fontWeight: "bold" }}>
                競賽規則
            </Typography>

            <Stack spacing={0.5}>
                <Typography sx={{ fontSize: 16, color: red[500] }}>(與真實市場之規則不同，請競賽者留意風險，自行評估操作)</Typography>
                <Typography sx={{ fontSize: 16 }}>1. 初始本金為 800 萬元，買進或賣出時都必須使用限價單。</Typography>
                <Typography sx={{ fontSize: 16 }}>2. 下單可全天候進行，並且永久有效，除非刪除。</Typography>
                <Typography sx={{ fontSize: 16 }}>3. 交易時間09:05 - 13:25，僅於有開盤時才成交機會。</Typography>
                <Typography sx={{ fontSize: 16 }}>4. 沒有 T+2 規則，成交後即從本金中扣除，庫存入庫。</Typography>
                <Typography sx={{ fontSize: 16 }}>5. 當價格漲停時，買單無法成交；當價格跌停時，賣單無法成交。</Typography>
                <Typography sx={{ fontSize: 16 }}>6. 所有投資標的均需考慮交易成本：交易稅 0.3%，手續費 0.1425%。</Typography>
                <Typography sx={{ fontSize: 16 }}>7. 不提供融資、融券，且不涉及除權息。</Typography>
                <Typography sx={{ fontSize: 16 }}>8. 處置股，全額交割股都不得交易。</Typography>
                <Typography sx={{ fontSize: 16, color: red[500] }}>9. 在競賽期間或結束後都會公開個人交易紀錄（不包括自動化策略）。</Typography>
            </Stack>

            <Typography sx={{ mt: 2, fontSize: 16, fontWeight: "bold" }}>
                活動獎勵
            </Typography>
            <Typography sx={{ fontSize: 16 }}>
            累積個人收益，可按特定匯率兌換現金。
            </Typography>

            {/* <Typography sx={{ mt: 2, fontSize: 16, fontWeight: "bold" }}>
                查看他人交易紀錄，扣除點數
            </Typography>
            <Typography sx={{ fontSize: 16 }}>
                {props.game.point}點
            </Typography> */}

            <Typography sx={{ mt: 2, fontSize: 16, fontWeight: "bold" }}>
                注意事項
            </Typography>

            <Stack spacing={0.8}>
                <Typography sx={{ fontSize: 16, color: red[500] }}>1. 兌換現金需先驗證手機號碼，請務必填寫正確。如有填寫錯誤、個人人為操作或簡訊接收硬體設備因素無法順利接收驗證碼驗證手機者，則視同放棄兌換現金的權利。</Typography>
                <Typography sx={{ fontSize: 16 }}>2. 任何利用程式漏洞所行之交易獲利，主辦方有權逕行取消或刪除該筆交易紀錄，不另行通知。</Typography>
                <Typography sx={{ fontSize: 16 }}>3. 中華民國稅法規定，獎項金額在新臺幣 1,000 元以上者，贈品價值總和將併入當年度個人綜合所得稅申報，承辦單位將依法開立扣繳憑單給予中獎者，中獎者應配合繳交身分證正反面影本供承辦廠商作為申報依據；依稅法規定，凡獎品價值超過新臺幣 20,000 元者，承辦單位依法代得獎者扣繳10%之稅金。</Typography>
                <Typography sx={{ fontSize: 16 }}>4. 活動過程中若因不可抗拒或電腦系統故障等因素，直接或間接造成承辦單位無法履行其全部或部分義務，或參加者電腦或資訊設備故障、損壞、延誤或資料有訛誤或其他失責情況，承辦單位均無須負任何責任。</Typography>
                <Typography sx={{ fontSize: 16 }}>5. 得獎後須於期限內填寫並回傳獎金領據，逾期將視同放棄獎金。</Typography>
                <Typography sx={{ fontSize: 16 }}>6. 參賽者之個資，主辦單位應有負責資安保密之管控。</Typography>
                <Typography sx={{ fontSize: 16 }}>7. 本競賽活動如有任何因電腦、網路、技術或其他不可歸責於主辦單位之事由，使參賽者或得獎者上傳或登錄之資料有所遺失、錯誤、無法辨識或毀損導致資料無效之情況，主辦單位不負任何法律責任，參賽者與得獎者亦不得有任何異議。</Typography>
                <Typography sx={{ fontSize: 16 }}>8. 凡報名參賽者，即視為同意本活動各項內容及規定，若有任何未盡事宜或不可抗拒因素而有所異動，依中華民國法律辦理之，主辦單位保有變更內容權力。若有任何更動，皆以活動網站公告為準，不另行通知。</Typography>
            </Stack>

        </div>
    );
}
