import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import { getId } from '../util/ID'
import AlertD from '../util/AlertD';
import { red } from '@mui/material/colors';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ActionRule from './ActionRule';
import ActionRule1 from './ActionRule1';


export default function SignUp(props) {
    const [open, setOpen] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);
    const [checked, setChecked] = React.useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const join = () => {
        if (checked) {
            let param = {
                sn: getId(),
                gid: props.gid,
                uid: localStorage.getItem("game_user_id"),
                phone: localStorage.getItem("game_phone"),
                name: localStorage.getItem("game_name"),
            }

            if (localStorage.getItem("game_lic") != null) {
                param['lic'] = localStorage.getItem("game_lic")
            }

            fetch("/game/v1/app/join", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result != null) {
                            if (result.status == 0 || result.status === -2) {
                                setOpen(false);
                                if (props.close != null) props.close(false)

                                if (props.refresh != null) {
                                    props.refresh()
                                }
                            } else if (result.status === -1) {
                                setAlertMsg("手機與姓名無法匹配。")
                                setShowAlert(true)
                            }
                        } else {
                            setAlertMsg("很抱歉，伺服器發生錯誤，請稍後再嘗試")
                            setShowAlert(true)
                        }
                    }
                );
        } else {
            setAlertMsg("閱讀完規則，記得選取同意")
            setShowAlert(true)
        }

    }

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };

    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);


    return (
        <div>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}

            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    {"報名" + props.game.name}
                </DialogTitle>
                <DialogContent sx={{ p: 3 }}>
                    {(props.game.id === "1" || props.game.id === "2" || props.game.id === "3") && <ActionRule game={props.game} />}
                    {!(props.game.id === "1" || props.game.id === "2" || props.game.id === "3") && <ActionRule1 game={props.game} />}
                    <FormGroup>
                        <FormControlLabel required control={<Checkbox
                            checked={checked}
                            onChange={handleChange}
                        />
                        } label="同意並會遵守遊戲規則" />
                    </FormGroup>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>取消</Button>
                    <Button onClick={join} autoFocus>
                        報名
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
