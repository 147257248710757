import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { getId } from '../util/ID'
import AlertD from '../util/AlertD';
import { red } from '@mui/material/colors';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SearchCompany from './SearchCompany';

export default function SellDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);

    const [price, setPrice] = React.useState(null);
    const [volume, setVolume] = React.useState(null);
    const [note, setNote] = React.useState(null);

    const join = () => {
        if (price == null || volume == null) {
            setAlertMsg("請確實填寫交易資料")
            setShowAlert(true)
        } else {
            let param = {
                sn: getId(),
                gid: props.gid,
                uid: localStorage.getItem("game_user_id"),
                phone: localStorage.getItem("game_phone"),
                name: localStorage.getItem("game_name"),
                cor: {
                    gid: props.gid,
                    uid: localStorage.getItem("game_user_id"),
                    sid: props.row.company.stock_no,
                    price: price,
                    volume: volume,
                    tp: 1,
                    note:note
                }
            }

            if (localStorage.getItem("game_lic") != null) {
                param['lic'] = localStorage.getItem("game_lic")
            }


            fetch("/game/v1/stock/order", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result != null) {
                            if (result.status == 0) {
                                setOpen(false);
                                if (props.close != null) props.close(false)

                                if (props.refresh != null) {
                                    props.refresh()
                                }
                            } else if (result.status === -1) {
                                setAlertMsg("手機與姓名無法匹配。")
                                setShowAlert(true)
                            } else if (result.status === -3) {
                                setAlertMsg("目前活動未在進行中，無法操作。")
                                setShowAlert(true)
                            }
                        } else {
                            setAlertMsg("很抱歉，伺服器發生錯誤，請稍後再嘗試")
                            setShowAlert(true)
                        }
                    }
                );
        }
    }

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };

    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);


    return (
        <div>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}

            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                {/* <DialogTitle id="alert-dialog-title">
                    {"交易"}
                </DialogTitle> */}
                <DialogContent sx={{ p: 3 }}>
                    <Box display={"flex"}>
                        <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>賣出資料</Typography>
                        {/* <Typography sx={{ ml: 0.8, alignSelf: "center", fontSize: 14, color: red[600], fontWeight: "bold" }}>(無法修改，謹慎填寫)</Typography> */}
                    </Box>
                    <Stack spacing={2} direction="column" sx={{ mt: 1 }}>
                        {/* <SearchCompany setSID={setSID} /> */}
                        {/* <TextField
                            disabled
                            fullWidth
                            id="outlined-controlled"
                            label="股票"
                            value={props.row.company.short_name}
                            helperText=""
                        /> */}
                        <Stack spacing={0.5}>
                            {props.row != null && props.row.price != null && <Typography fontSize={18} >股票：{props.row.company.short_name}</Typography>}
                            {props.row != null && <Typography fontSize={18} >成本：{props.row.avg_cost.toFixed(2)}元</Typography>}
                            {props.row != null && <Typography fontSize={18} >庫存：{props.row.volume}股</Typography>}
                            {props.row != null && props.row.price != null && <Typography fontSize={18}  >目前市價：{props.row.price.p}元</Typography>}
                        </Stack>
                        <TextField
                            required
                            fullWidth
                            id="outlined-controlled"
                            label="賣出價格"
                            value={price}
                            helperText=""
                            onChange={(event) => {
                                setPrice(event.target.value);
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">元</InputAdornment>,
                            }}
                        />


                        <TextField
                            required
                            fullWidth
                            id="outlined-controlled"
                            label="賣出股數"
                            value={volume}
                            helperText=""
                            onChange={(event) => {
                                setVolume(event.target.value);
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">股</InputAdornment>,
                            }}
                        />

                        <TextField
                            fullWidth
                            id="note"
                            label="備註"
                            value={note}
                            helperText=""
                            onChange={(event) => {
                                setNote(event.target.value);
                            }}
                        />
                    </Stack>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>取消</Button>
                    <Button onClick={join} autoFocus>
                        賣出
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
