import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Height } from '../util/ScreenHeight'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

import { getId } from '../util/ID'
import TextField from '@mui/material/TextField';
import AlertD from '../util/AlertD';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import PaymentContainer1 from '../payment/PaymentContainer1';
import { Container } from '@mui/material';
import PhoneVerify from './PhoneVerify';
import GiveMeMoneyTable from './GiveMeMoneyTable';
import DrawMoney from './DrawMoney';
import { priceWord } from '../util/Money'

export default function Account(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);

    const [phone, setPhone] = React.useState(localStorage.getItem("game_phone"));
    const [nickName, setNickName] = React.useState(localStorage.getItem("game_nick_name"));
    const [name, setName] = React.useState(localStorage.getItem("game_name"));
    const [dis, setDis] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [pointItem, setPointItem] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);
    const [openSetting1, setOpenSetting1] = React.useState(false);
    const [drawItem, setDrawItem] = React.useState(null);
    const [IsPhoneVerify, setIsPhoneVerify] = React.useState(localStorage.getItem("phone_verify") === "1");


    const handleWarningClickOpen = () => {
        setOpen(false);
        del()
    };

    const handleWarningClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        getDraw()
    }, []);


    const join = () => {
        if (phone == null || nickName == null || name == null) {
            setAlertMsg("請確實填寫個人基本資料")
            setShowAlert(true)
        } else if (phone.length != 10) {
            setAlertMsg("手機號碼格式有誤")
            setShowAlert(true)
        } else {
            let param = {
                sn: getId(),
                phone: phone,
                nick_name: nickName,
                name: name,
            }

            if (localStorage.getItem("game_lic") != null) {
                param['lic'] = localStorage.getItem("game_lic")
            }


            fetch("/game/v1/app/signUp", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result != null) {
                            if (result.status == 0) {
                                localStorage.setItem("game_user_id", result.uid)
                                localStorage.setItem("game_phone", phone)
                                localStorage.setItem("game_name", name)
                                localStorage.setItem("game_nick_name", nickName)
                                setDis(true)

                                if (result.is_verify) {
                                    localStorage.setItem("phone_verify", 1)
                                    setIsPhoneVerify(true)
                                } else {
                                    localStorage.removeItem("phone_verify")
                                    setIsPhoneVerify(false)
                                }

                            } else if (result.status === -1) {
                                setAlertMsg("基本資料輸入錯誤。")
                                setShowAlert(true)
                                setDis(false)
                            }
                        } else {
                            setAlertMsg("很抱歉，伺服器發生錯誤，請稍後再嘗試")
                            setShowAlert(true)
                            setDis(false)
                        }
                    }
                );
        }
    }

    const del = () => {
        let param = {
            sn: getId(),
            uid: localStorage.getItem("game_user_id"),
            phone: localStorage.getItem("game_phone"),
            name: localStorage.getItem("game_name"),
        }

        if (localStorage.getItem("game_lic") != null) {
            param['lic'] = localStorage.getItem("game_lic")
        }


        fetch("/game/v1/account/del", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        if (result.status == 0) {
                            localStorage.removeItem("game_user_id")
                            localStorage.removeItem("game_phone")
                            localStorage.removeItem("game_name")
                            localStorage.removeItem("game_nick_name")

                            setPhone(null)
                            setName(null)
                            setNickName(null)
                            setDis(false)
                        }
                    }
                }
            );
    }


    const getDraw = () => {
        if (localStorage.getItem("game_user_id") != null) {
            let param = {
                uid: localStorage.getItem("game_user_id"),
                phone: localStorage.getItem("game_phone"),
                name: localStorage.getItem("game_name"),
            }


            fetch("/game/v1/draw/get", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result != null) {
                            if (result.status == 0) {
                                setDrawItem(result.draw)

                                if (result.draw.is_verify) {
                                    localStorage.setItem("phone_verify", 1)
                                    setIsPhoneVerify(true)
                                } else {
                                    localStorage.removeItem("phone_verify")
                                    setIsPhoneVerify(false)
                                }

                            }
                        }
                    }
                );
        } else {
            let param = {
            }

            fetch("/game/v1/draw/get1", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result != null) {
                            if (result.status == 0) {
                                setDrawItem(result.draw)
                            }
                        }
                    }
                );
        }
    }

    const openDrawDialog = () => {
        if (localStorage.getItem("phone_verify") != "1") {
            setAlertMsg("請先完成手機號碼驗證")
            setShowAlert(true)
        } else {
            setOpenSetting1(true)
        }
    }

    return (
        <>
            <Box sx={{ width: '100%', position: 'absolute', top: Height.header, bottom: props.ismobile || fullScreen ? 55 : 0, overflowY: 'scroll' }}>
                {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
                {openSetting ? <PhoneVerify phone={phone} refresh={getDraw} open={openSetting} close={setOpenSetting}></PhoneVerify> : null}
                {openSetting1 ? <DrawMoney phone={phone} refresh={getDraw} open={openSetting1} close={setOpenSetting1}></DrawMoney> : null}


                {/* <Box sx={{ p: 1 }} > */}
                <Container sx={{ p: 2 }} maxWidth="lg">
                    <Grid container spacing={2}
                        sx={{
                            '--Grid-borderWidth': '1px',
                            // borderTop: 'var(--Grid-borderWidth) solid',
                            // borderLeft: 'var(--Grid-borderWidth) solid',
                            borderColor: 'divider',
                            '& > div': {
                                //   borderRight: 'var(--Grid-borderWidth) solid',
                                borderBottom: 'var(--Grid-borderWidth) solid',
                                borderColor: 'divider',
                            }
                        }}
                    >
                        <Grid xs={12} md={6}>
                            <Box>
                                <Typography sx={{ width: "100%", textAlign: "center", fontSize: 28, fontWeight: "bold" }}>基本資料</Typography>
                                <Typography sx={{ width: "100%", textAlign: "center", ml: 0.8, alignSelf: "center", fontSize: 14, color: red[600], }}>無法修改，謹慎填寫</Typography>
                            </Box>
                            <Stack spacing={2} direction="column" sx={{ mt: 1, p: 1 }}>
                                <Stack direction={"row"} spacing={0.2}>
                                    <TextField
                                        size="small"
                                        required
                                        fullWidth
                                        disabled={localStorage.getItem("game_user_id") != null || dis}
                                        id="outlined-controlled"
                                        label="手機"
                                        value={phone}
                                        // helperText="獲勝者將通過手機通知，請務必填寫正確資訊。若無法順利聯絡到得獎者，則視同放棄獎項，恕不另行補發。"
                                        onChange={(event) => {
                                            setPhone(event.target.value);
                                        }}
                                    />

                                    {IsPhoneVerify && <Button color="success" size='small' disableElevation variant="contained" sx={{ whiteSpace: "nowrap", borderRadius: 0 }} >已驗證</Button>}
                                    {!IsPhoneVerify && localStorage.getItem("game_phone") != null && <Button size='small' disableElevation variant="contained" sx={{ whiteSpace: "nowrap", borderRadius: 0 }} onClick={e => setOpenSetting(true)}>驗證手機</Button>}
                                </Stack>


                                <TextField
                                    size="small"
                                    required
                                    fullWidth
                                    disabled={localStorage.getItem("game_user_id") != null || dis}
                                    id="outlined-controlled"
                                    label="姓名"
                                    value={name}
                                    // helperText=""
                                    onChange={(event) => {
                                        setName(event.target.value);
                                    }}
                                />

                                <TextField
                                    size="small"
                                    required
                                    fullWidth
                                    disabled={localStorage.getItem("game_user_id") != null || dis}
                                    id="outlined-controlled"
                                    label="暱稱"
                                    value={nickName}
                                    // helperText="公開顯示的暱稱"
                                    onChange={(event) => {
                                        setNickName(event.target.value);
                                    }}
                                />
                                <Stack spacing={0.2}>
                                    <Typography fontSize={12}>1. 獲勝者將通過手機通知，請務必填寫正確資訊。若無法順利聯絡到得獎者，則視同放棄獎項，恕不另行補發。</Typography>
                                    <Typography fontSize={12}>2. 領獎時需要核對姓名，請務必填寫正確資訊。若填寫錯誤導致無法順利領獎，則視同得獎者放棄獎項，恕不另行補發。</Typography>
                                    <Typography fontSize={12}>3. 暱稱會公開顯示，也無法修改。</Typography>
                                </Stack>
                            </Stack>
                            <Box sx={{ p: 1 }}>
                                {localStorage.getItem("game_user_id") == null && !dis ? <Button disableElevation sx={{ mt: 3 }} size="large" fullWidth onClick={join} variant='contained'>
                                    確定
                                </Button> : <Button disableElevation color="inherit" sx={{ mt: 3 }} size="large" fullWidth onClick={e => setOpen(true)} variant='contained'>
                                    刪除基本資料與比賽紀錄
                                </Button>}
                            </Box>


                            <Dialog
                                open={open}
                                onClose={handleWarningClose}
                            >
                                <DialogTitle id="alert-dialog-title">
                                    {"確定要刪除?"}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        會將您的所有比賽資料與個人基本資料通通刪除，並且無法回復。確定要刪除？
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleWarningClose}>取消</Button>
                                    <Button onClick={handleWarningClickOpen} autoFocus>
                                        確定
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Grid>


                        <Grid xs={12} md={6}>
                            <Stack direction={"row"} spacing={1} sx={{ justifyContent: "center" }}>
                                <Typography sx={{ fontSize: 28, fontWeight: "bold" }}>獎金</Typography>
                                {/* <Button sx={{alignSelf:"center"}} disableElevation size="small" variant='contained' onClick={e => setOpenSetting1(true)}>提領</Button> */}
                            </Stack>

                            <Box sx={{ mt: 0 }}>
                                {drawItem != null && <Typography sx={{ width: "100%", textAlign: "center", fontSize: 14, color: red[600] }}>累積收益{priceWord(drawItem.earn_rate)}可兌換現金{priceWord(drawItem.cash_rate)}，目前累積收益是{priceWord(drawItem.earn)}</Typography>}

                            </Box>
                            <GiveMeMoneyTable drawItem={drawItem} />
                            <Button disabled={!(drawItem != null && drawItem.earn >= drawItem.earn_rate)} disableElevation color="inherit" sx={{ mt: 5 }} size="large" fullWidth onClick={e => openDrawDialog()} variant='contained'>
                                兌換現金
                            </Button>
                        </Grid>
                    </Grid>

                </Container>
                {/* </Box > */}

            </Box>
        </>
    );
}