import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import MobileApp from './MobileApp';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container);
// root.render(<React.StrictMode>
//   <MobileApp />
// </React.StrictMode>);
root.render(
  <MobileApp />
);


// ReactDOM.render(
//   <React.StrictMode>
//     <MobileApp />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
