import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { Height } from '../util/ScreenHeight'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import SignUp from './SignUp';
import { getId } from '../util/ID'
import LeaderBoard from './LeaderBoard';
import WatchTable from './WatchTable';
import HeroTable from './HeroTable';
import HeroMyTable from './HeroMyTable';
import AssetData from './AssetData';


export default function HeroBoard(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [result, setResult] = React.useState(null);
    const [myResult, setMyResult] = React.useState(null);
    const [moneyTrend, setMoneyTrend] = React.useState(null);
    const [point, setPoint] = React.useState(0)

    useEffect(() => {
        get()
    }, []);

    const get = () => {
        let param = {
            uid: localStorage.getItem("game_user_id"),
            phone: localStorage.getItem("game_phone"),
            name: localStorage.getItem("game_name"),
        }

        fetch("/game/v1/hero/get", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null && result.status === 0) {
                        setResult(result.hero)
                        setMyResult(result.my_hero)
                        setMoneyTrend(result.money_trend)
                    }
                }
            );
    }



    return (
        <>
            <Box sx={{ width: '100%', position: 'absolute', top: Height.header, bottom: props.ismobile || fullScreen ? 55 : 0, overflowY: 'scroll' }}>
                <Container sx={{ p: 2 }} maxWidth="md">

                    {myResult != null && <Box sx={{ mb: 1 }}>
                        <Typography sx={{ width: "100%", textAlign: "center", fontSize: 24, fontWeight: "bold" }}>個人戰績</Typography>
                    </Box>}

                    {moneyTrend != null && <AssetData moneyTrend={moneyTrend}></AssetData>}
                    <HeroMyTable refresh={get} result={myResult} />

                    <HeroTable refresh={get} result={result} />
                </Container>
            </Box >
        </>
    );
}