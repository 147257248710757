import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TransTableAction from './TransTableAction';
import { Add, Fingerprint, Looks, Receipt, Remove, Sailing } from '@mui/icons-material';
import SellDialog from './SellDialog';
import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple, green } from '@mui/material/colors';
import AlertD from '../util/AlertD';
import { getId } from '../util/ID'
import { Chip, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PersonalDialog1 from './PersonalDialog1';
import WatchAction from './WatchAction';
// import { Adsense } from '@ctrl/react-adsense';
import { priceWord } from '../util/Money'
import { red, grey, blue } from '@mui/material/colors';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import HeroCard from './HeroCard';
import WatchDialog from './WatchDialog';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontSize: 16,
        whiteSpace: "nowrap"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
        whiteSpace: "nowrap"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // '&:nth-of-type(odd)': {
    //     backgroundColor: theme.palette.action.hover,
    // },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function HeroMyTable(props) {
    const theme = useTheme();

    const [openSetting, setOpenSetting] = React.useState(false);
    const [row, setRow] = React.useState(null);
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);
    const [openSetting1, setOpenSetting1] = React.useState(false);
    const [openSetting2, setOpenSetting2] = React.useState(false);
    const [wid, setWid] = React.useState(null);
    const [name, setName] = React.useState(null);

    const getStatus = (status) => {
        if (status === 0) return "隱藏中"
        else if (status === 1) return "尚未開始"
        else if (status === 2) return "活動中"
        else if (status === 3) return "已結束"
        else if (status === 4) return "已刪除"
        else return ""
    }

    const open = (value) => {
        if (value.game.in_grace || value.game.status === 3) {
            setRow(value)
            setOpenSetting1(true)
        } else {
            setAlertMsg("此刻已無法查看交易紀錄。")
            setShowAlert(true)
        }
    }

    const getBgColor = (value) => {
        if (localStorage.getItem("game_user_id") != null && localStorage.getItem("game_user_id") === value) {
            return theme.palette.mode === 'dark' ? blue[900] : blue[100]
        } else return null
    }

    const openWatch = (wid, name) => {
        setWid(wid)
        setName(name)
        setOpenSetting2(true)
    }

    return (
        <Box sx={{ p: 1 }}>
            {openSetting ? <SellDialog row={row} refresh={props.refresh} gid={props.gid} open={openSetting} close={setOpenSetting}></SellDialog> : null}
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            {openSetting1 ? <PersonalDialog1 refreshPoint={props.refreshPoint} gid={row.gid} wid={row.wid} open={openSetting1} close={setOpenSetting1}></PersonalDialog1> : null}
            {openSetting2 ? <WatchDialog name={name} wid={wid} open={openSetting2} close={setOpenSetting2}></WatchDialog> : null}


            <Stack direction={"column"} spacing={0.1}>
                {/* <Typography fontSize={14} sx={{ mt: 2 }}>今日匯率：累積收益250萬元換250元。</Typography> */}
                {/* <Typography fontSize={14} sx={{ mt: 2 }}>級數：每擊敗100名對手提升1級。</Typography> */}
            </Stack>
            {props.result != null && <HeroCard result={props.result} />}


            {props.result != null && <TableContainer component={Paper}>
                <Table sx={{}} size="small">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">級數</StyledTableCell>
                            <StyledTableCell align="center">英雄暱稱</StyledTableCell>
                            <StyledTableCell align="center">參加場次</StyledTableCell>
                            <StyledTableCell align="center">勝率</StyledTableCell>
                            <StyledTableCell align="center">累積報酬率</StyledTableCell>
                            <StyledTableCell align="center">累積收益</StyledTableCell>
                            <StyledTableCell align="center">擊敗對手</StyledTableCell>
                            <StyledTableCell align="center">買&賣次數</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <StyledTableRow key={props.result.uid} onClick={e => openWatch(props.result.uid, props.result.name)} >
                            <StyledTableCell align="left">
                                {/* <Avatar sx={{ bgcolor: "#344157" }} variant="square">
                                        {row.rate}級
                                    </Avatar> */}
                                {/* <Button disableElevation fullWidth variant="text" >第{props.result.rate}級</Button> */}
                                <Typography  >第{props.result.rate}級</Typography>
                            
                            </StyledTableCell>
                            <StyledTableCell align="center">{props.result.name}</StyledTableCell>
                            <StyledTableCell align="center">
                                {props.result.join_count}場
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <Button color={props.result.win_rate > 60 ? "error" : "inherit"} fullWidth disableElevation variant="contained">
                                    {props.result.win_rate}%</Button></StyledTableCell>

                            <StyledTableCell align="center">
                                <Button fullWidth disableElevation variant="outlined" color={props.result.roi >= 0 ? "error" : "success"}>{props.result.roi}%</Button>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <Button fullWidth disableElevation variant="text" color={props.result.earn >= 0 ? "error" : "success"}>{priceWord(props.result.earn)}</Button>

                            </StyledTableCell>
                            <StyledTableCell align="center">
                                {props.result.kill}人
                            </StyledTableCell>

                            <StyledTableCell align="center">{props.result.buy_count}次 / {props.result.sell_count}次</StyledTableCell>

                        </StyledTableRow>


                        {/* {(props.result == null || (props.result != null && props.result.watch != null
                            && props.result.watch.length === 0)) &&
                            <StyledTableRow key={"empty1"}>
                                <StyledTableCell align="center" colSpan={8}>{"無任何資料"}</StyledTableCell></StyledTableRow>} */}


                    </TableBody>
                </Table>
            </TableContainer>}
            {/* <Adsense
                client="ca-pub-8613274553211553"
                slot="1654340287"
                style={{ display: 'block' }}
                layout="in-article"
                format="fluid"
            /> */}
        </Box>
    );
}
