import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { Height } from '../util/ScreenHeight'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import SignUp from './SignUp';
import { getId } from '../util/ID'
import LeaderBoard from './LeaderBoard';
import WatchTable from './WatchTable';


export default function WatchBoard(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [result, setResult] = React.useState(null);
    const [point, setPoint] = React.useState(0)

    useEffect(() => {
        get()
    }, []);

    const get = () => {
        if (localStorage.getItem("game_user_id") != null) {
            let param = {
                sn: getId(),
                gid: props.gid,
                uid: localStorage.getItem("game_user_id"),
                phone: localStorage.getItem("game_phone"),
                name: localStorage.getItem("game_name"),
            }

            if (localStorage.getItem("game_lic") != null) {
                param['lic'] = localStorage.getItem("game_lic")
            }


            fetch("/game/v1/watch/get", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result != null && result.status === 0) {
                            setResult(result)
                            setPoint(result.point)
                        }
                    }
                );
        }
    }


    return (
        <>
            <Box sx={{ width: '100%', position: 'absolute', top: Height.header, bottom: props.ismobile || fullScreen ? 55 : 0, overflowY: 'scroll' }}>
                <Container sx={{ p: 2 }} maxWidth="md">
                    <WatchTable refresh={get} result={result} gid={props.gid} />
                </Container>
            </Box >
        </>
    );
}