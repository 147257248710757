import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TransTableAction from './TransTableAction';
import { Add, Fingerprint, Looks, Receipt, Remove, Sailing } from '@mui/icons-material';
import SellDialog from './SellDialog';
import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple, green } from '@mui/material/colors';
import AlertD from '../util/AlertD';
import { getId } from '../util/ID'
import { Chip } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PersonalDialog1 from './PersonalDialog1';
import WatchAction from './WatchAction';
// import { Adsense } from '@ctrl/react-adsense';
import { priceWord } from '../util/Money'
import { red, grey, blue } from '@mui/material/colors';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import WatchDialog from './WatchDialog';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontSize: 16,
        whiteSpace: "nowrap"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
        whiteSpace: "nowrap"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // '&:nth-of-type(odd)': {
    //     backgroundColor: theme.palette.action.hover,
    // },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function HeroTable(props) {
    const theme = useTheme();

    const [openSetting, setOpenSetting] = React.useState(false);
    const [row, setRow] = React.useState(null);
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);
    const [openSetting1, setOpenSetting1] = React.useState(false);
    const [openSetting2, setOpenSetting2] = React.useState(false);
    const [wid, setWid] = React.useState(null);
    const [name, setName] = React.useState(null);


    const getStatus = (status) => {
        if (status === 0) return "隱藏中"
        else if (status === 1) return "尚未開始"
        else if (status === 2) return "活動中"
        else if (status === 3) return "已結束"
        else if (status === 4) return "已刪除"
        else return ""
    }

    const open = (value) => {
        if (value.game.in_grace || value.game.status === 3) {
            setRow(value)
            setOpenSetting1(true)
        } else {
            setAlertMsg("此刻已無法查看交易紀錄。")
            setShowAlert(true)
        }
    }

    const getBgColor = (value) => {
        if (localStorage.getItem("game_user_id") != null && localStorage.getItem("game_user_id") === value) {
            return theme.palette.mode === 'dark' ? blue[900] : blue[100]
        } else return null
    }

    const openWatch = (wid, name) => {
        setWid(wid)
        setName(name)
        setOpenSetting2(true)
    }

    return (
        <Box sx={{ p: 1 }}>
            {openSetting ? <SellDialog row={row} refresh={props.refresh} gid={props.gid} open={openSetting} close={setOpenSetting}></SellDialog> : null}
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            {openSetting1 ? <PersonalDialog1 refreshPoint={props.refreshPoint} gid={row.gid} wid={row.wid} open={openSetting1} close={setOpenSetting1}></PersonalDialog1> : null}
            {openSetting2 ? <WatchDialog name={name} wid={wid} open={openSetting2} close={setOpenSetting2}></WatchDialog> : null}


            <Box sx={{ mb: 1 }}>
                <Typography sx={{ width: "100%", textAlign: "center", fontSize: 24, fontWeight: "bold" }}>TOP50戰績</Typography>
            </Box>

            {/* <Adsense
                client="ca-pub-8613274553211553"
                slot="1654340287"
                style={{ display: 'block' }}
                layout="in-article"
                format="fluid"
            />
            
            <Typography sx={{mt:3}}>級數：每擊敗100名對手提升1級，每提升10級後，擊敗對手數量增加一倍才能升級，達到特定級數可以獲得獎勵。</Typography> */}
            <TableContainer component={Paper}>
                <Table sx={{}} size="small">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">級數</StyledTableCell>
                            <StyledTableCell sx={{  zIndex: 999, position: "sticky", left: 0 }} align="center">英雄暱稱</StyledTableCell>
                            <StyledTableCell align="center">參加場次</StyledTableCell>
                            <StyledTableCell align="center">勝率</StyledTableCell>
                            <StyledTableCell align="center">累積報酬率</StyledTableCell>
                            <StyledTableCell align="center">累積收益</StyledTableCell>
                            <StyledTableCell align="center">擊敗對手</StyledTableCell>
                            <StyledTableCell align="center">買&賣次數</StyledTableCell>
                            

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.result != null && props.result.map((row) => (
                            <StyledTableRow onClick={e => openWatch(row.uid, row.name)} key={row.uid} >
                                <StyledTableCell align="left" style={{ backgroundColor: getBgColor(row.uid) }}>
                                    {/* <Avatar sx={{ bgcolor: "#344157" }} variant="square">
                                        {row.rate}級
                                    </Avatar> */}
                                    <Typography  >第{row.rate}級</Typography>
                                    {/* <Button size='small' disableElevation fullWidth variant="text" >第{row.rate}級</Button> */}
                                </StyledTableCell>
                                <StyledTableCell sx={{  zIndex: 998, position: "sticky", left: 0, bgcolor: theme.palette.mode === "light" ? grey[50] : grey[900], color: theme.palette.mode === "light" ? "#000000" : "#FFFFFF" }} align="center">{row.name}</StyledTableCell>
                                <StyledTableCell align="center">
                                    {row.join_count}場
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <Button size='small' color={row.win_rate > 60 ? "error" : "inherit"} fullWidth disableElevation variant="contained">
                                        {row.win_rate}%</Button></StyledTableCell>

                                <StyledTableCell align="center">
                                    <Button size='small' fullWidth disableElevation variant="outlined" color={row.roi >= 0 ? "error" : "success"}>{row.roi}%</Button>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <Button size='small' fullWidth disableElevation variant="text" color={row.earn >= 0 ? "error" : "success"}>{priceWord(row.earn)}</Button>

                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {row.kill}人
                                </StyledTableCell>

                                <StyledTableCell align="center">{row.buy_count}次 / {row.sell_count}次</StyledTableCell>


                            </StyledTableRow>
                        ))}

                        {(props.result == null || (props.result != null && props.result.watch != null
                            && props.result.watch.length === 0)) &&
                            <StyledTableRow key={"empty1"}>
                                <StyledTableCell align="center" colSpan={8}>{"無任何資料"}</StyledTableCell></StyledTableRow>}


                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
