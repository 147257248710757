import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TransTableAction from './TransTableAction';
import { Add, Fingerprint, Looks, Receipt, Remove, Sailing } from '@mui/icons-material';
import SellDialog from './SellDialog';
import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple, green } from '@mui/material/colors';
import AlertD from '../util/AlertD';
import { getId } from '../util/ID'
import { Chip, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import WatchAction from './WatchAction';
import { Adsense } from '@ctrl/react-adsense';
import { priceWord } from '../util/Money'
import { red, grey, blue } from '@mui/material/colors';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: grey[900],
        color: theme.palette.common.white,
        fontSize: 16,
        whiteSpace: "nowrap"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
        whiteSpace: "nowrap"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function GiveMeMoneyTable(props) {
    const theme = useTheme();

    const [openSetting, setOpenSetting] = React.useState(false);
    const [row, setRow] = React.useState(null);
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);
    const [openSetting1, setOpenSetting1] = React.useState(false);

    const convertStatus =(status) => {
        if(status === 0) {
            return "等待處理"
        } else if(status === 1)
            return "處理中"
        else if(status === 2)
            return "轉帳完成"
        else if(status === -1)
            return "錯誤資訊，請聯絡管理人員"
        else return ""
    }

    return (
        <Box sx={{ mt: 2, p: 0 }}>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}

            <TableContainer component={Paper}>
                <Table sx={{}} size="small">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">日期</StyledTableCell>
                            <StyledTableCell align="center">兌換匯率</StyledTableCell>
                            <StyledTableCell align="center">收益</StyledTableCell>
                            <StyledTableCell align="center">獎金</StyledTableCell>
                            <StyledTableCell align="center">狀態</StyledTableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.drawItem != null && props.drawItem.list.map(row => {
                            return <StyledTableRow key={row.id} >
                                <StyledTableCell align="center">
                                    {row.ymd}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {priceWord(row.earn)} / {priceWord(row.cash)}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {priceWord(row.cost)}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {priceWord(row.money)}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {convertStatus(row.status)}
                                </StyledTableCell>
                            </StyledTableRow>
                        })}


                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
