import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TransTableAction from './TransTableAction';
import { Fingerprint, Sailing } from '@mui/icons-material';
import SellDialog from './SellDialog';

import Stack from '@mui/material/Stack';
import { green, red, blueGrey, blue, pink, grey } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import DetailPrice from './DetailPrice';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontSize: 16,
        whiteSpace: "nowrap"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
        whiteSpace: "nowrap"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function StorageTable(props) {
    const [openSetting, setOpenSetting] = React.useState(false);
    const [openSetting1, setOpenSetting1] = React.useState(false);
    const [sid, setSid] = React.useState(null);

    const [row, setRow] = React.useState(null);

    const getROI = (row) => {
        if(row.avg_cost != "NaN")
        return ((((row.price.p * 0.995575) - row.avg_cost) * 100) / row.avg_cost).toFixed(2)
        else return 0
    }

    const sell = (value) => {
        setRow(value)
        setOpenSetting(true)
    }

    const getColor = (row) => {
        if (row != null && row.price != null) {
            if (row.price.p > row.price.y) return red[400]
            else if (row.price.p < row.price.y) return green[400]
            else return null
        } else return null
    }

    const getInv = (row) => {
        if (row != null && row.price != null) {
            let pp = (((row.price.p - row.price.y) * 100) / (row.price.y)).toFixed(1)
            let i = (row.price.p - row.price.y).toFixed(2)
            if (row.price.p > row.price.y) return "▲" + i + ' (' + pp + '%)'
            else if (row.price.p < row.price.y) return "▼" + i + ' (' + pp + '%)'
            else return i + '元' + ' (' + pp + '%)'
        } else return '▲0元'
    }

    const openDetail = (row) => {
        setSid(row.company.stock_no)
        setOpenSetting1(true)
    }

    return (
        <>
            {openSetting ? <SellDialog row={row} refresh={props.refresh} gid={props.gid} open={openSetting} close={setOpenSetting}></SellDialog> : null}
            {openSetting1 ? <DetailPrice endYMD={props.endYMD} gid={props.gid} sid={sid} wid={props.wid} open={openSetting1} close={setOpenSetting1}></DetailPrice> : null}

            <TableContainer component={Paper}>
                <Table sx={{}} size="small" >
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">股票</StyledTableCell>
                            <StyledTableCell align="left">類股</StyledTableCell>
                            <StyledTableCell align="center">成本</StyledTableCell>
                            <StyledTableCell align="center">股數</StyledTableCell>
                            <StyledTableCell align="center">報酬率</StyledTableCell>
                            {props.action && <StyledTableCell align="center">動作</StyledTableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.result != null && props.result.storage != null && props.result.storage.data.map((row) => (
                            <StyledTableRow key={row.sid} onClick={e => openDetail(row) }>
                                <StyledTableCell >
                                    {row.company.short_name} {row.company.stock_no}

                                    <Stack direction='row' spacing={0.5} sx={{ pr: 1, alignSelf: 'center' }} >
                                        <Typography color={getColor(row)} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }} >{row.price != null ? row.price.p : "-"}</Typography>
                                        <Typography color={getColor(row)} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }} >{getInv(row)}</Typography>
                                    </Stack>
                                </StyledTableCell>
                                <StyledTableCell sx={{}} >
                                    {row.company.inductry}
                                </StyledTableCell>


                                <StyledTableCell align="center">{row.avg_cost != "NaN" ? row.avg_cost.toFixed(2):"-"}元</StyledTableCell>
                                <StyledTableCell align="center">{row.volume}股</StyledTableCell>
                                <StyledTableCell align="center">
                                    <Button fullWidth disableElevation color={row.price.p > row.avg_cost ? "error" : "success"} variant="contained">
                                        {getROI(row)}%</Button></StyledTableCell>
                                {props.action && <StyledTableCell align="center">
                                    <Button onClick={e => sell(row)} color="inherit" startIcon={<Sailing />} sx={{}} variant='contained' disableElevation fullWidth>手動賣出</Button>
                                </StyledTableCell>}
                            </StyledTableRow>
                        ))}
                        {props.result != null && (props.result.storage == null || (props.result.storage != null && props.result.storage.data.length === 0)) &&
                            <StyledTableRow key={"empty1"}>
                                <StyledTableCell align="center" colSpan={props.action ? 6 : 5}>{"無任何資料"}</StyledTableCell></StyledTableRow>}


                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
