import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { Add, DocumentScanner, Fingerprint, FlutterDash, Looks, PanoramaFishEye, Sailing, Watch } from '@mui/icons-material';
import SellDialog from './SellDialog';
import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple, green } from '@mui/material/colors';
import AlertD from '../util/AlertD';
import { getId } from '../util/ID'
import PointAlertDialog from './PointAlertDialog';
import PersonalDialog1 from './PersonalDialog1';
import Stack from '@mui/material/Stack';
import { red } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import StatisticsDialog from './StatisticsDialog';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontSize: 16,
        whiteSpace: "nowrap"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
        whiteSpace: "nowrap"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // '&:nth-of-type(odd)': {
    //     backgroundColor: theme.palette.action.hover,
    // },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function StatisticsShortTable(props) {
    const [row, setRow] = React.useState(null);
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);
    // const [openSetting, setOpenSetting] = React.useState(false);
    const [openSetting1, setOpenSetting1] = React.useState(false);
    const [wid, setWid] = React.useState(null);
    const [point, setPoint] = React.useState(0)

    const addWatch = (wid) => {
        if (localStorage.getItem("game_user_id") != null) {
            let param = {
                sn: getId(),
                gid: props.gid,
                uid: localStorage.getItem("game_user_id"),
                phone: localStorage.getItem("game_phone"),
                name: localStorage.getItem("game_name"),
                wid: wid
            }

            if (localStorage.getItem("game_lic") != null) {
                param['lic'] = localStorage.getItem("game_lic")
            }


            fetch("/game/v1/watch/add", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result != null && result.status === 0) {
                            setAlertMsg("關注成功")
                            setShowAlert(true)
                            if (props.refresh != null) props.refresh()
                        }
                    }
                );
        }
    }

    const seeReport = () => {
        // if (props.dPoint > props.point && !props.inGrace) {
        //     setAlertMsg("點數不足，無法查看交易紀錄。")
        //     setShowAlert(true)
        // } else {
        //     setOpenSetting1(true)
        // }

        if (props.inGrace || props.gameStatus1 === 3) {
            setOpenSetting1(true)
        } else {
            setAlertMsg("此刻已無法查看交易紀錄。")
            setShowAlert(true)            
        }

    }

    const getColor = (row) => {
        if (row != null && row.cur_price != null) {
            if (row.cur_price.i > 0) return red[400]
            else if (row.cur_price.i < 0) return green[400]
            else return null
        } else return null
    }

    const getInv = (row) => {
        if (row != null && row.cur_price != null) {
            let pp = ((row.cur_price.i * 100) / (row.cur_price.p - row.cur_price.i)).toFixed(1)

            if (row.cur_price.i > 0) return "▲" + row.cur_price.i + ' (' + pp + '%)'
            else if (row.cur_price.i < 0) return "▼" + row.cur_price.i + ' (' + pp + '%)'
            else return row.cur_price.i + '元' + ' (' + pp + '%)'
        } else return '▲0元'
    }

    return (
        <>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            {/* {openSetting ? <PointAlertDialog open={openSetting} close={setOpenSetting}></PointAlertDialog> : null} */}
            {/* {openSetting1 && <PersonalDialog1 refreshPoint={props.refreshPoint} gid={props.gid} wid={wid} open={openSetting1} close={setOpenSetting1}></PersonalDialog1>} */}
            {openSetting1 && <StatisticsDialog refreshPoint={props.refreshPoint} gid={props.gid} open={openSetting1} close={setOpenSetting1}></StatisticsDialog>}



            <TableContainer component={Paper}>
                <Table sx={{}} size="small" >
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">股票</StyledTableCell>
                            <StyledTableCell align="left">類股</StyledTableCell>
                            <StyledTableCell align="center">數量</StyledTableCell>
                            <StyledTableCell align="center">平均成本</StyledTableCell>
                            <StyledTableCell align="center">筆數</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.result != null && props.result.map((row) => (
                            <StyledTableRow key={row.sid}>

                                {row.company != null ? <StyledTableCell>
                                    {row.company.short_name} {row.company.stock_no}

                                    <Stack direction='row' spacing={0.5} sx={{ pr: 1, alignSelf: 'center' }} >
                                        <Typography color={getColor(row)} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }} >{row.cur_price != null ? row.cur_price.p : "-"}</Typography>
                                        <Typography color={getColor(row)} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }} >{getInv(row)}</Typography>
                                    </Stack>
                                </StyledTableCell>:<StyledTableCell></StyledTableCell>}
                                {row.company != null ? <StyledTableCell sx={{}} >
                                    {row.company.inductry}
                                </StyledTableCell>:<StyledTableCell></StyledTableCell>}

                                <StyledTableCell align="center">{row.volume}股</StyledTableCell>
                                <StyledTableCell align="center">{row.price}元</StyledTableCell>
                                <StyledTableCell align="center">{row.people}筆</StyledTableCell>
                            </StyledTableRow>
                        ))}

                        {props.result != null && props.count > props.result.length &&
                            <StyledTableRow key={"click1"}>
                                <StyledTableCell align="center" colSpan={5} sx={{padding:0}}>
                                    <Button disabled={!(props.inGrace || props.gameStatus1 === 3)} onClick={e => seeReport()} size="large" variant='contained' color="inherit" fullWidth disableElevation>
                                        看完整{props.label}統計資料（還有{props.count - props.result.length}筆）。
                                    </Button>
                                </StyledTableCell></StyledTableRow>}
                        .
                        {props.result != null && props.result.length === 0 &&
                            <StyledTableRow key={"empty1"}>
                                <StyledTableCell align="center" colSpan={5} sx={{}}　>無任何{props.label}統計資料</StyledTableCell></StyledTableRow>}


                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
